import React from 'react';
import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import classnames from 'classnames';

export function Draggable(props) {
    const { attributes, isDragging, listeners, setNodeRef, transform } = useDraggable({
        id: props.id,
        disabled: props.disabled,
    });

    const style = {
        // Outputs `translate3d(x, y, 0)`
        opacity: props.disabled ? .5 : 1,
        transform: CSS.Translate.toString(transform),
        transitionProperty: isDragging && 'box-shadow'
    };

    return (
        <button ref={setNodeRef} style={style} {...listeners} {...attributes} className={classnames({
            'dragging': isDragging,
            'disabled': props.disabled,
            'draggable': !props.disabled
        })}>
            {props.children}
        </button>
    );
}