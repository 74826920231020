import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import classnames from 'classnames';

import { Item } from './item.js';

export function Sortable(props) {
    const {
        attributes,
        isDragging,
        listeners,
        setNodeRef,
        transform,
        transition
    } = useSortable({ id: props.name, disabled: props.disabled });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition
    };

    return (
        <div
            ref={setNodeRef}
            style={style}
            {...props}
            {...attributes}
            {...listeners}
            className={classnames('draggable',
            {'dragging': isDragging}
        )}
        >
            <Item name={props.name} />
        </div>
    );
}
