import { useEffect, useRef } from 'react'
import AudioPlayer from 'components/audio'

const audioPlayer = AudioPlayer()
audioPlayer.setInstrument('/assets/instrument-mp3.js') // Public folder
// audioPlayer.setInstrument('timpani')

export default function Keys(props) {

    const { notes, Music, currentNoteId, setPlayedNote, startGameComplete, handleGameCompleted } = props
    const keys = useRef([])
    const music = Music.tracks[1].notes

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown)
        document.addEventListener('keyup', handleKeyUp)

        return function cleanup() {
            document.removeEventListener('keydown', handleKeyDown)
            document.removeEventListener('keyup', handleKeyUp)
        }
    }, [currentNoteId])

    useEffect(() => {
        if (startGameComplete) {
            Music.tracks[1].notes.forEach(note => {
                const getNote = notes.find(n => n.note === note.name)
                const button = keys.current[getNote.id]

                setTimeout(() => {
                    playNote(button, getNote.id, note)

                    setTimeout(() => {
                        removeDown(button)
                    }, note.duration * 1000 - 150, button)
                }, (note.time) * 1000, button, getNote)
            });

            const lastNote = Music.tracks[1].notes[Music.tracks[1].notes.length - 1]
            setTimeout(() => {
                handleGameCompleted()
            }, (lastNote.time + lastNote.duration) * 1000)
        }
    }, [startGameComplete])

    const handleKeyDown = (e) => {
        if (e.repeat) return
        e.preventDefault()

        const key = e.key.toUpperCase() // Pressed key

        const note = notes.find(n => n.key === key)
        if (!note) return

        const button = keys.current[note.id]
        if (!button) return

        playNote(button, note.id, music[currentNoteId])
    }

    const handlePointerDown = (e) => {
        const button = e.target
        const noteId = Array.from(button.parentNode.children).indexOf(button)
        if (noteId < 0 || noteId > notes.length - 1) return

        playNote(button, noteId, music[currentNoteId])
    }

    const handleKeyUp = (e) => {
        const key = e.key.toUpperCase() // Pressed key
        
        const note = notes.find(n => n.key === key)
        if (!note) return

        const button = keys.current[note.id]
        if (!button) return

        removeDown(button)
    }

    const playNote = (b, note, currentNote) => {
        b.classList.add('active')
        const currTime = audioPlayer?.currentTime()

        if (currentNote?.name === notes[note].note) {
            audioPlayer.playNote(currentNote.name, currTime, {
                attack: currentNote.velocity / 10,
                duration: currentNote.duration
            })
        } else {
            audioPlayer.playNote(notes[note].note, currTime, {
                attack: 0.1,
                duration: 0.02
            })
        }
        setPlayedNote(notes[note])
    }

    const removeDown = (b) => {
        if (b.target) b = b.target
        b.classList.remove('active')
    }

    const cleanNoteName = (note) => {
        // Remove octave
        return note.note.replace(/\d/g, '')
    }

    return (
        <div className='keys'>
            { notes.map((note, index) => (
                <button 
                    key={index} 
                    ref={(e) => keys.current[index] = e}
                    data-key={note.key}
                    data-note={note.note}
                    className={`note-${note.note}`}

                    // check if pointerEvent is supported by the browser
                    { ...(window.PointerEvent ? 
                        {
                            onPointerDown: handlePointerDown,
                            onPointerUp: removeDown,
                            onPointerLeave: removeDown
                        } : {
                            onMouseDown: handlePointerDown,
                            onMouseUp: removeDown,
                            onMouseLeave: removeDown
                        }
                    )}
                >
                    <div>{cleanNoteName(note)}</div>
                </button>
            )) }
        </div>
    )
}