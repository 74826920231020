import { useEffect } from 'react'

const ErrorMessage = ({ visible, setVisible, text, duration = 10000 }) => {

    useEffect(() => {
        if (visible) {
            const error = document.createElement('div')
            error.className = 'errorContainer'
            error.innerText = text
            document.body.appendChild(error)
            setTimeout(() => {
                error.classList.add('show')
            }, 100)

            const remover = setTimeout(() => {
                error.classList.remove('show')
                setTimeout(() => {
                    document.body.removeChild(error)
                    setVisible(false)
                }, 500)
            }, duration)

            error.addEventListener('click', () => {
                clearTimeout(remover)
                error.classList.remove('show')
                setTimeout(() => {
                    document.body.removeChild(error)
                    setVisible(false)
                }, 500)
            })
        }
    }, [visible])
}

export default ErrorMessage