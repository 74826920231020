import { useRef, useEffect } from "react"

const Logo = ({ gameID, selectedColor, possibleColors, logoColors, setLogoColors, gameCompleted, setSave }) => {

    const logoAreas = [
        'M10 81.08 410 10v54.92L10 136V81.08z',
        'M10 223.08 410 152v54.92L10 278v-54.92z',
        'M10 365.08 410 294v54.92L10 420v-54.92z',
        'M410 64.92 810 136V81.08L410 10v54.92z',
        'M410 206.92 810 278v-54.92L410 152v54.92z',
        'M410 348.92 810 420v-54.92L410 294v54.92z',
        'M10 135.92 410 207v-54.92L10 81v54.92z',
        'M10 277.92 410 349v-54.92L10 223v54.92z',
        'M10 419.92 410 491v-54.92L10 365v54.92z',
        'M410 152.08 810 81v54.92L410 207v-54.92z',
        'M410 294.08 810 223v54.92L410 349v-54.92z',
        'M410 436.08 810 365v54.92L410 491v-54.92z'
    ]

    const paths = useRef([])

    const handlePaint = (e, index) => {
        if (isNaN(selectedColor) || selectedColor === null || index >= logoColors.length) return

        const { target } = e
        const color = possibleColors[selectedColor]

        target.setAttribute('fill', color)

        setLogoColors(logoColors => logoColors.map((currentColor, i) => {
            if (i === index) {
                return selectedColor
            }
            return currentColor
        }))
    }

    useEffect(() => {
        paths.current.forEach((path, index) => {
            const color = possibleColors[logoColors[index]] ?? '#f0f0f0'
            path.setAttribute('fill', color)
        })
    }, [gameCompleted])

    useEffect(() => {
        setSave(prev => ({
            ...prev,
            game: { 
                ...prev.game,
                [gameID]: { colors: logoColors }
            }
        }))
    }, [logoColors])

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 820 492.19">
            {logoAreas.map((area, index) => {
                return (
                    <path
                        key={index}
                        onClick={(e) => handlePaint(e, index)}
                        fill={isNaN(logoColors[index]) ? '#f0f0f0' : possibleColors[logoColors[index]]}
                        ref={e => paths.current[index] = e}
                        stroke="black"
                        strokeWidth="1"
                        strokeDasharray="7,7"
                        strokeLinejoin="bevel"
                        d={area}
                    />
                )
            })}
        </svg>
    )
}

export default Logo
