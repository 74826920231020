import { CSSTransition } from 'react-transition-group';
import { useTranslation } from 'react-i18next';

import Media from 'components/media';
import ArrowRight from 'assets/vectors/arrow_white_right.svg';
import BubbleArrow from 'assets/vectors/bubble_arrow_3.svg';

const Help = ({ helpVisible, setHelpVisible, props }) => {

    const { t } = useTranslation();
    const { session, setSession } = props;

    const handleAccept = () => {
        setSession({
            ...session,
            helpVisible: false
        });

        setHelpVisible(false);
    }

    if (!helpVisible) return null;

    return (
        <CSSTransition
            in={helpVisible}
            appear={true}
            timeout={400}
            classNames="fade"
        >
            <div className={`helpBoxContainer${!helpVisible ? ' fade-enter' : ''}`}>
                <div className='helpBox'>
                    <Media audio={`map_help`} video={`map_help`}>
                        <p>{t(`pages.map.help`)}</p>
                        <img className='arrow left' src={BubbleArrow} />
                    </Media>
                    <button className='button' onClick={handleAccept}>
                        {t('pages.map.start')}<img src={ArrowRight} />
                    </button>
                </div>
                <div className='overlay' onClick={handleAccept} />
            </div>
        </CSSTransition>
    );
};

export default Help;