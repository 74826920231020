import { useEffect, useState, useRef, useContext } from 'react'

import { SaveContext, CompleteGame } from 'components/save'
import Navigation from 'components/navigation'
import Transition from 'components/navigation/transition'
import Help from './help'
import Card from './card'
import './game4.scss'

import random from 'components/functions/random'

function shuffleCards(array) {
    array = array.concat(array)
    const length = array.length
    for (let i = length; i > 0; i--) {
        const randomIndex = Math.floor(random() * i)
        const currentIndex = i - 1
        const temp = array[currentIndex]
        array[currentIndex] = array[randomIndex]
        array[randomIndex] = temp
    }
    return array
}

export default function Game4() {

    const gameID = 4
    const { save, setSave } = useContext(SaveContext)

    const [difficulty, setDifficulty] = useState(1)
    const [openCards, setOpenCards] = useState([])
    const [clearedCards, setClearedCards] = useState({})
    const [shouldDisableAllCards, setShouldDisableAllCards] = useState(false)
    const [gameCompleted, setGameCompleted] = useState(false)
    const timeout = useRef(null)

    let uniqeCards = []

    // Nubmer of cards to be shown is 9
    const numberOfCards = 9
    
    for (let i = 1; i <= numberOfCards; i++) {
        uniqeCards.push({
            id: i,
            image: require(`assets/images/game_4/level_${difficulty}_card_${i}.png`)
        });
    }

    const [cards, setCards] = useState(() =>
        shuffleCards(uniqeCards)
    )

    const disable = () => {
        setShouldDisableAllCards(true)
    }
    const enable = () => {
        setShouldDisableAllCards(false)
    }

    const evaluate = () => {
        const [first, second] = openCards
        enable()
        if (cards[first].id === cards[second].id) {
            setClearedCards((prev) => ({ ...prev, [cards[first].id]: true }))

            setOpenCards([])
            return
        }
        // This is to flip the cards back after 500ms duration
        timeout.current = setTimeout(() => {
            setOpenCards([])
        }, 500)
    }
    const handleCardClick = (index) => {
        if (openCards.length === 1) {
            setOpenCards((prev) => [...prev, index])
            disable()
        } else {
            clearTimeout(timeout.current)
            setOpenCards([index])
        }
    }

    useEffect(() => {
        let timeout = null
        if (openCards.length === 2) {
            timeout = setTimeout(evaluate, 300)
        }
        return () => {
            clearTimeout(timeout)
        }
    }, [openCards])

    useEffect(() => {
        handleReset()
    }, [difficulty])

    const handleReset = () => {
        setClearedCards({})
        setOpenCards([])
        setShouldDisableAllCards(false)
        setCards(shuffleCards(uniqeCards))
        setGameCompleted(false)
    }

    const handleGameCompleted = () => {
        CompleteGame({ id: gameID, save, setSave })
        setGameCompleted(true)
    }

    useEffect(() => {
        if (Object.keys(clearedCards).length === uniqeCards.length) {
            handleGameCompleted()
        }
    }, [clearedCards])

    const checkIsFlipped = (index) => {
        return openCards.includes(index)
    }

    const checkIsInactive = (card) => {
        return Boolean(clearedCards[card.id])
    }

    const props = {
        difficulty,
        setDifficulty,
        gameCompleted,
        handleReset,
        gameID
    }

    return (
        <Transition className={`game game${gameID}`} style={{backgroundImage:'url('+require(`assets/images/game_${gameID}/canvas_level_${difficulty}.png`)+')'}}>
            <div className='container'>
                {cards.map((card, index) => {
                    return (
                        <Card
                            difficulty={difficulty}
                            key={index}
                            card={card}
                            index={index}
                            isDisabled={shouldDisableAllCards}
                            isInactive={checkIsInactive(card)}
                            isFlipped={checkIsFlipped(index)}
                            onClick={handleCardClick}
                        />
                    )
                })}
            </div>
            <Navigation Help={Help} helpProps={props} />
        </Transition>
    )
}
