import random from 'components/functions/random'

export default function shuffle(array) {
	if (!Array.isArray(array)) {
		throw new TypeError(`Expected an array, got ${typeof array}`)
	}

	array = [...array]

	for (let index = array.length - 1; index > 0; index--) {
		const newIndex = Math.floor(random() * (index + 1));
		[array[index], array[newIndex]] = [array[newIndex], array[index]]
	}

	return array
}