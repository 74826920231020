import { useEffect, useState, createContext } from 'react'
import store from 'store2'

export const SaveContext = createContext()

export const SessionContext = createContext()

export default function Save({ cookieConsentIsSet, children }) {

    const savedSave = store('save') ?? {}
    const [save, setSave] = useState(savedSave)

    const savedSession = store.session('session') ?? {}
    const [session, setSession] = useState({ ...savedSession, cookieConsent: cookieConsentIsSet })

    useEffect(() => {
        setSession(prev => ({ ...prev, cookieConsent: cookieConsentIsSet }))
    }, [cookieConsentIsSet]);

    useEffect(() => {
        store('save', save)
    }, [save])

    useEffect(() => {
        store.session('session', session)
    }, [session])

    return (
        <SaveContext.Provider value={{ save, setSave }}>
            <SessionContext.Provider value={{ session, setSession }}>
                {children}
            </SessionContext.Provider>
        </SaveContext.Provider>
    )
}

export function CompleteGame({ id, save, setSave }) {
    if (!save.completedGames?.includes(id)) {
        setSave({
            ...save,
            completedGames: [...(save.completedGames ?? []), id]
        })
    }
}