import { useState } from 'react'

import "./navigation.scss"
import Back from './back'
import Fullscreen from './fullscreen'

const Navigation = ({ charater = 'button_berta', showLogos, Help, helpDefault = true, helpProps }) => {

    const [helpVisible, setHelpVisible] = useState(helpDefault)

    const charaterImg = require(`assets/images/components/${charater}.png`)

    const showHelp = () => {
        setHelpVisible(true)
    }

    return (
        <>
            <Back showLogos={showLogos}>
                <button className='help' onClick={showHelp}>
                    <img src={charaterImg} />
                </button>
            </Back>
            <Help
                helpVisible={helpVisible}
                setHelpVisible={setHelpVisible}
                props={helpProps}
            />
            <Fullscreen />
        </>
    )
}

export default Navigation