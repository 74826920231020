import { useRef, useState, useEffect, useContext } from 'react'
import { SessionContext } from 'components/save'
import * as Sentry from '@sentry/react'
import { useTranslation } from 'react-i18next'

import ErrorMessage from 'components/modal/error'

const Media = (props) => {
    const { t } = useTranslation()
    const { session, setSession } = useContext(SessionContext)
    const { 
        audio,
        audioFolder = 'help/',
        video,
        videoLoop = true,
        audioLoop = false,
        children,
        delay = 2000,
        className = 'textContainer'
    } = props

    const [playing, setPlaying] = useState(false)
    const [muted, setMuted] = useState(session.audioMuted ?? false)
    const [error, setError] = useState(false)

    const audioRef = useRef()
    const muteButtonRef = useRef()
    const videoRef = useRef()

    // automatically play audio on mount if audio is not muted
    useEffect(() => {
        audioRef.current.pause()
        // Stop audio if muted is muted
        if (muted || !audio) return

        // Don't play audio immediately
        setTimeout(() => {
            // TODO: Stop currently playing audio if any
            // if (window.audioRef && !window.audioRef.paused) {
            //     window.audioRef.pause()
            //     // window.audioRef.paused = true // Fallback
            //     window.audioRef.currentTime = 9999
            // }
            // window.audioRef = audioRef.current

            try {
                let promise = audioRef.current?.play()
                // convert promise to string
                if (promise !== undefined) {
                    promise.then(_ => {
                        setPlaying(true)
                        audioRef.current.onended = () => {
                            setPlaying(false)
                        }
                    }).catch(_ => {
                        muteButtonRef.current.click()
                        // add tooltip that the playback was blocked by the browser
                        setError(true)
                    })
                }
            } catch (error) {
                Sentry.captureException(error)
            }
        }, delay)

        // pause and play the audio if the window is focused or not
        document.addEventListener('visibilitychange', () => {
            if (document.hidden) {
                audioRef.current?.pause()
            } else {
                audioRef.current?.play()
            }
        })
    }, [audio])

    // Toggle audio on click
    useEffect(() => {
        setPlaying(!muted)

        setSession(session => ({
            ...session,
            audioMuted: muted
        }))
    }, [muted])

    useEffect(() => {
        try {
            if (playing) {
                audioRef.current.currentTime = 0
                audioRef.current.play()

                if (videoRef.current) {
                    videoRef.current.play()
                }
            } else {
                audioRef.current.pause()

                // If loop is disabled on video, stop video
                // Disabled loop means more then mouth animation
                if (videoLoop && videoRef.current) {
                    videoRef.current.currentTime = 0
                    videoRef.current.pause()
                }
            }
        } catch (error) {
            Sentry.captureException(error)
        }
    }, [playing])

    const audioUpdate = () => {
        setMuted(prevMuted => !prevMuted)
    }

    return (
        <>
            <ErrorMessage visible={error} setVisible={setError} text={t('general.paybackBlocked')} />
            <div className={className}>
                <div className='flex-end'>
                    <button ref={muteButtonRef} className='mute' onClick={audioUpdate}>
                        <img src={require(`assets/vectors/sound_${muted ? 'off' : 'on' }.svg`)} />
                        <audio src={require(`assets/audio/${audioFolder}${audio}.mp3`)} ref={audioRef} muted={muted} loop={audioLoop} />
                    </button>
                </div>
                {children}
            </div>
            {video && (
                <div className='animation'>
                    <video ref={videoRef} key={video} loop={videoLoop} autoPlay muted poster={require(`assets/videos/caps/${video}.jpg`)}>
                        <source src={require(`assets/videos/vp8-webm/${video}.webm`)} type='video/webm' />
                        <source src={require(`assets/videos/${video}.mp4`)} type='video/mp4' />
                    </video>
                </div>
            )}
        </>
    )
}

export default Media