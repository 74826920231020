import { useEffect, useState, useContext } from 'react';

import { SaveContext, CompleteGame } from 'components/save';
import Navigation from 'components/navigation';
import Transition from 'components/navigation/transition';
import Help from './help';
import Logo from './logo';
import './game2.scss';

export default function Game2() {

    const gameID = 2;
    const { save, setSave } = useContext(SaveContext);

    const [gameCompleted, setGameCompleted] = useState(false);

    const [selectedColor, setSelectedColor] = useState(null);
    const [logoColors, setLogoColors] = useState(save.game?.[gameID]?.colors || Array(12).fill(null));

    const possibleColors = [
        '#E30504','#E43B04','#FF7A01','#FEC200','#FFF600','#B3E10D',
        '#8F1D58','#73388A','#4B04E6','#0100E4','#0CC3AD','#00CB00'
    ];

    const handleReset = () => {
        setLogoColors(Array(12).fill(null));
        setGameCompleted(false);
    };

    const handleGameCompleted = () => {
        CompleteGame({ id: gameID, save, setSave });
        setGameCompleted(true);
    };

    const setActiveColor = (i) => {
        setSelectedColor(i);
    };

    useEffect(() => {
        if (save?.game?.[gameID]?.colors?.filter(e => e === null).length === 0) handleGameCompleted()
    }, [save]);

    const props = {
        gameCompleted,
        handleReset,
        gameID
    };

    return (
        <Transition className={`game game${gameID}`} style={{backgroundImage:'url('+require(`assets/images/game_${gameID}/background.png`)+')'}}>
            <Navigation Help={Help} helpProps={props} />
            <div className='container'>
                <Logo
                    gameID={gameID}
                    selectedColor={selectedColor}
                    possibleColors={possibleColors}
                    logoColors={logoColors}
                    setLogoColors={setLogoColors}
                    gameCompleted={gameCompleted}
                    setSave={setSave}
                />
                <ul className='colorButtons'>
                    {possibleColors.map((color, index) => {
                        return (
                            <li key={index} className={index == selectedColor ? 'active': ''} style={{backgroundColor:color}} onClick={() => setActiveColor(index)}></li>
                        )
                    })}
                </ul>
            </div>
        </Transition>
    );
}
