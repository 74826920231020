import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import ErrorMessage from 'components/modal/error'
import supportedBrowsers from './supportedBrowsers'

export default function CompatibilityCheck() {
    const { t } = useTranslation()

    const [error, setError] = useState(supportedBrowsers.test(navigator.userAgent) === false)

    return (
        <ErrorMessage visible={error} setVisible={setError} duration={5000} text={t('general.old-browser')} />
    )
}