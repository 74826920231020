import React from "react";
import { useTranslation } from 'react-i18next'
import ModalLayout from 'components/modal'
import Media from 'components/media'

const Modal = ({ gameID, visible, close }) => {

    const { t } = useTranslation()

    const title = t(`games.game${gameID}.win.title`);
    const text = t(`games.game${gameID}.win.text`);
    const image = require(`assets/images/game_${gameID}/final_content.jpg`);

    return (
        <ModalLayout title={title} visible={visible} close={close}>
            <img className='center' src={image} alt="Portrait" />
            <Media audio='end_screen' audioFolder={`game_${gameID}/`}>
                <p>{text}</p>
            </Media>
        </ModalLayout>
    );
};

export default Modal;
