import React from 'react'
import { useTranslation } from 'react-i18next'

import { Autoplay, Pagination, EffectCards, Keyboard, Mousewheel, A11y } from "swiper"
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'
import 'swiper/css'
import "swiper/css/effect-cards"
import "swiper/css/pagination"

import useWindowDimensions from 'components/hooks/useWindowDimensions'
import LoadingIcon from 'assets/vectors/button_redo.svg'
import Images from './fetchImages'

const Image = () => {

    const { width } = useWindowDimensions()
    const { t } = useTranslation()
    const images = Images()

    const Loading = () => (
        <div className='loading'>
            <img className='rotate' src={LoadingIcon} />
            {t('general.loading')}
        </div>
    )

    const Image = ({ image }) => {
        const swiper = useSwiper()

        return (
            <figure className='image'>
                <img src={image.src} alt={image.alt} onLoad={() => {swiper.update()}} />
                { image.caption && <figcaption>{image.caption}</figcaption> }
            </figure>
        )
    }

    return (
        <Swiper
            className='main'
            slidesPerView='auto'
            watchSlidesProgress={true}
            updateOnImagesReady={true}
            preloadImages={true}
            spaceBetween={120}
            centeredSlides={true}
            grabCursor={true}
            pagination={{
                type: 'progressbar',
                clickable: true
            }}
            autoplay={{
                delay: 2500,
                disableOnInteraction: true,
            }}
            effect={width <= 768 ? 'cards' : 'slide'}
            modules={[Autoplay, Pagination, EffectCards, Keyboard, Mousewheel, A11y]}
        >
            { images.length < 1 ? <Loading /> :
                images.map((image, i) => (
                    <SwiperSlide key={i}>
                        <Image image={image} />
                    </SwiperSlide>
                ))
            }
        </Swiper>
    )
}

export default Image