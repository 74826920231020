import { useTranslation } from 'react-i18next'

import Back from 'components/navigation/back'
import Transition from 'components/navigation/transition'
import './mail.scss'
import Form from './Form'

export default function Mail() {
    const { t } = useTranslation()

    return (
        <Transition className='game page mail' style={{ backgroundImage: 'url(' + require(`assets/images/records/background.jpg`) + ')' }}>
            <Back showLogos={true} />
            <h1>{ t('pages.post.title') }</h1>
            <Form />
        </Transition>
    )
}

