import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Back from 'components/navigation/back'
import Transition from 'components/navigation/transition'
import './interview.scss'
import InterviewEntries from './Interviews'
import Media from 'components/media'

export default function Interviews() {
    const { t } = useTranslation()

    const [current, setCurrent] = useState(1)

    return (
        <Transition className='game page interviews cards' style={{ backgroundImage: 'url(' + require(`assets/images/records/background.jpg`) + ')' }}>
            <Back showLogos={true} />
            <h1 className='has-right-button'>{ t('pages.interviews.title') }</h1>
            <Media audio={`interview_${current}`} audioFolder='interviews/' delay={250} className='player viewport-button' />
            <InterviewEntries setCurrent={setCurrent} />
        </Transition>
    )
}