import React from 'react'
import { useSortable } from '@dnd-kit/sortable'
import classnames from 'classnames'

export function Sortable({ id, gameID }) {
    const {
        attributes,
        isDragging,
        listeners,
        setNodeRef,
    } = useSortable({ id: id })

    return (
        <div
            ref={setNodeRef}
            {...attributes}
            {...listeners}
            className={classnames({
                'dragging': isDragging,
                'post': id == 1
            })}
        >
            {id == 1 && <img className='carrier' src={require(`assets/images/game_${gameID}/Berta.png`)} />}
        </div>
    )
}
