import React from "react";
import ModalLayout from 'components/modal';
import Media from 'components/media';

const Modal = ({ modal, visible, close }) => {

    if (!modal || !modal.person) return null;

    const { name, text, person, gameID } = modal;

    const image = require(`assets/images/game_${gameID}/person_${person.image}.jpg`);
    const audio = `person_${person.image}`;

    return (
        <ModalLayout title={name} visible={visible} close={close}>
            <img className='center' src={image} alt="Portrait" />
            <Media audio={audio} audioFolder={`game_${gameID}/`} delay={250}>
                <p>{text}</p>
            </Media>
        </ModalLayout>
    );
};

export default Modal;
