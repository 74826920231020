import { useState, useEffect } from 'react'
import { CSSTransition } from 'react-transition-group'
import { useTranslation } from 'react-i18next'
import classname from 'classnames'

import ArrowRight from 'assets/vectors/arrow_white_right.svg'
import Media from 'components/media'

const UncoveredInfo = ({ props, digs, foundObjectID }) => {
    const { t } = useTranslation()
    const { gameID, gameCompleted, startGameComplete, handleGameCompleted } = props
    const [visibility, setVisibility] = useState(false)
    const [foundObjectImages, setFoundObjectImages] = useState([false, false, false])
    const foundObjectCount = foundObjectImages.filter(x => x === true).length

    const hideStatus = () => {
        setVisibility(false)

        // Get every dig with object that is not 0 (empty)
        const digsWithObjects = digs.filter(dig => dig.object > 0)
        // If every object is found, end game
        if (digsWithObjects.every(dig => dig.uncovered)) {
            handleGameCompleted()
            setFoundObjectImages([false, false, false])
        }
    }

    useEffect(() => {
        if (foundObjectID <= 0) return
        setVisibility(true)

        // Set foundObjectID position to true
        setFoundObjectImages(prev => {
            prev[foundObjectID - 1] = true
            return prev
        })
    }, [foundObjectID])

    return (
        <>
            <CSSTransition
                in={!visibility && (!gameCompleted && !startGameComplete)}
                timeout={300}
                classNames="fade"
                unmountOnExit
            >
                <div className='foundInfo'>
                    <p>{t('general.found-out-of.1')} {foundObjectCount} {t('general.found-out-of.2')} 3 {t('general.found-out-of.3')}</p>
                    {foundObjectImages.map((found, i) => (
                        <img className={classname({ 'complete': found })} src={require(`assets/images/game_${gameID}/object_${i + 1}.jpg`)} key={i} />
                    ))}
                </div>
            </CSSTransition>
            <CSSTransition
                in={visibility}
                timeout={300}
                classNames="fade"
                unmountOnExit
            >
                <div className='uncoveredInfo'>
                    {foundObjectID > 0 && (
                        <div className='helpBox'>
                            <h2>{t('general.found-out-of.1')} {foundObjectCount} {t('general.found-out-of.2')} 3 {t('general.found-out-of.3')}</h2>
                            <div className='foundImage'>
                                {foundObjectImages.map((found, i) => (
                                    <img className={classname({ 'complete': found })} src={require(`assets/images/game_${gameID}/object_${i + 1}.jpg`)} key={i} />
                                ))}
                            </div>
                            <img className='picture' src={require(`assets/images/game_${gameID}/object_${foundObjectID}.jpg`)} />
                            <Media audio={`object_${foundObjectID}`} audioFolder={`game_${gameID}/`}>
                                <p>{t(`games.game${gameID}.objects.${foundObjectID}`)}</p>
                            </Media>
                            <button className='button' onClick={hideStatus}>
                                {foundObjectCount === 3 ? t('general.continue') : t(`games.game${gameID}.keepOn`)}<img src={ArrowRight} />
                            </button>
                        </div>
                    )}
                    <div className='overlay'></div>
                </div>
            </CSSTransition>
        </>
    )
}

export default UncoveredInfo