import SoundFontPlayer from "soundfont-player"
import AudioContext from "./AudioContext"
import * as Sentry from "@sentry/react"

const NullSoundFontPlayerNoteAudio = {
    stop() { },
}

const NullSoundFontPlayer = {
    play() {
        return NullSoundFontPlayerNoteAudio
    },
}

const AudioPlayer = () => {
    //Audio Context
    const audioContext = AudioContext && new AudioContext()
    //soundPlayer
    let soundPlayer = NullSoundFontPlayer
    //setInstrument
    const Player = {
        setInstrument(instrumentName) {
            SoundFontPlayer.instrument(audioContext, instrumentName)
                .then(soundfontPlayer => {
                    soundPlayer = soundfontPlayer
                })
                .catch(error => {
                    Sentry.captureException(error)
                    soundPlayer = NullSoundFontPlayer
                })
        },
        playNote(note, when, options) {
            if (when && options)    soundPlayer.play(note, when, options)
            else                    soundPlayer.play(note)
        },
        playNotes(notes, delay = 0) {
            soundPlayer.schedule(
                audioContext.currentTime + delay,
                notes.map((note) => ({
                    time: note.time,
                    note: note.name,
                    duration: note.duration
                }))
            )
        },
        currentTime: () => audioContext.currentTime,
    }

    return Player
}

export default AudioPlayer