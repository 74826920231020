import { useState, useContext } from 'react'

import { SaveContext, CompleteGame } from 'components/save'
import Navigation from 'components/navigation'
import Transition from 'components/navigation/transition'

import Music from './music.json'
import Help from './help'
import Keys from './keys'
import Sheet from './sheet'
import './game6.scss'

export default function Game6() {

    const gameID = 6
    const { save, setSave } = useContext(SaveContext)

    const [startGameComplete, setStartGameComplete] = useState(false)
    const [gameCompleted, setGameCompleted] = useState(false)

    const [playedNote, setPlayedNote] = useState(null)
    const [currentNoteId, setCurrentNoteId] = useState(0)

    const notes = [
        { id: 0, note: 'C4', key: 'Q' },
        { id: 1, note: 'D4', key: 'W' },
        { id: 2, note: 'E4', key: 'E' },
        { id: 3, note: 'F4', key: 'R' },
        { id: 4, note: 'G4', key: 'T' },
        { id: 5, note: 'A4', key: 'Z' }
    ]

    const handleReset = () => {
        setGameCompleted(false)
        setStartGameComplete(false)
    }

    const handleGameCompleted = () => {
        CompleteGame({ id: gameID, save, setSave })
        setGameCompleted(true)
    }

    const props = {
        gameCompleted,
        handleReset,
        gameID
    }

    return (
        <Transition className={`game game${gameID}`} style={{backgroundImage:'url('+require(`assets/images/game_${gameID}/background.jpg`)+')'}}>
            <div className='container'>
                <Sheet 
                    gameID={gameID} 
                    Music={Music}
                    playedNote={playedNote}
                    setPlayedNote={setPlayedNote}
                    currentNoteId={currentNoteId}
                    setCurrentNoteId={setCurrentNoteId}
                    setStartGameComplete={setStartGameComplete}
                />
                <Keys 
                    notes={notes}
                    Music={Music}
                    currentNoteId={currentNoteId}
                    setPlayedNote={setPlayedNote}
                    startGameComplete={startGameComplete}
                    handleGameCompleted={handleGameCompleted}
                />
            </div>

            <Navigation charater='button_bert' Help={Help} helpProps={props} />
        </Transition>
    )
}