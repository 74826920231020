import React from 'react'
import { useTranslation } from 'react-i18next'

import Back from 'components/navigation/back'
import Transition from 'components/navigation/transition'
import './gallery.scss'
import Image from './Image'

export default function Records() {
    const { t } = useTranslation()

    return (
        <Transition className='game page gallery' style={{ backgroundImage: 'url(' + require(`assets/images/records/background.jpg`) + ')' }}>
            <Back showLogos={true} />
            <h1>{ t('pages.gallery.title') }</h1>
            <Image />
        </Transition>
    )
}

