import { useEffect, useState, useContext } from 'react'
import { CSSTransition } from 'react-transition-group'
import classnames from 'classnames'
import CrossfadeImage from "react-crossfade-image"
import { DndContext, useSensor, MouseSensor, TouchSensor, KeyboardSensor, useSensors } from '@dnd-kit/core';
import { restrictToFirstScrollableAncestor } from "@dnd-kit/modifiers";

import { SaveContext, CompleteGame } from 'components/save'
import Navigation from 'components/navigation'
import Transition from 'components/navigation/transition'
import Help from './help'
import { Draggable } from 'components/dnd/draggable';
import { Droppable } from 'components/dnd/droppable';
import './game8.scss'
import ArrowRight from 'assets/vectors/arrow_white_right.svg'

import { t } from 'i18next';

export default function Game8() {

    const gameID = 8
    const { save, setSave } = useContext(SaveContext)

    // Alle Keider: a1, a3, b1, b2, b3, c1, c2, e1, f1, g1, h1, i1

    const buttons = [
        { id: 'b2', allowed: ['e1', 'g1'] },
        { id: 'h1', allowed: ['f1'] },
        { id: 'c1', allowed: ['c2'] },
        { id: 'f1', allowed: [] },
        { id: 'b1', allowed: ['c1', 'i1'] },
        { id: 'c2', allowed: ['f1'] },
        { id: 'g1', allowed: ['e1'] },
        { id: 'a3', allowed: ['b2'] },
        { id: 'a1', allowed: ['b1', 'b3'] },
        { id: 'e1', allowed: ['f1'] },
        { id: 'b3', allowed: ['h1'] },
        { id: 'i1', allowed: ['f1'] }
    ]

    const sets = {
        a1_b1_c1_c2: {
            id: 'soldat',
            title: t(`games.game${gameID}.sets.soldier`),
            backgroundImage: 2
        },
        a1_b1_i1: {
            id: 'junge',
            title: t(`games.game${gameID}.sets.boy`),
            backgroundImage: 4
        },
        a1_b3_h1: {
            id: 'senator',
            title: t(`games.game${gameID}.sets.senator`),
            backgroundImage: 1
        },
        a3_b2_e1: {
            id: 'maedchen',
            title: t(`games.game${gameID}.sets.girl`),
            backgroundImage: 4
        },
        a3_b2_g1_e1: {
            id: 'frau',
            title: t(`games.game${gameID}.sets.women`),
            backgroundImage: 3
        }
    }

    const [allowedButtons, setAllowedButtons] = useState(['a1', 'a3'])
    const [activeDress, setActiveDress] = useState(['empty'])
    const [showCharacter, setShowCharacter] = useState(false)
    const [backgroundImage, setBackgroundImage] = useState(0)

    const [startGameComplete, setStartGameComplete] = useState(false)
    const [gameCompleted, setGameCompleted] = useState(false)

    const handleReset = () => {
        setStartGameComplete(false)
        setGameCompleted(false)

        setAllowedButtons(['a1', 'a3'])
        setActiveDress(['empty'])
        setBackgroundImage(0)
    }

    const handleGameCompleted = () => {
        CompleteGame({ id: gameID, save, setSave })
        setGameCompleted(true)
    }

    const handleDragEnd = (event) => {
        const { active, over } = event;

        if (
            over === null || // dropped but not over a dropable
            active === null // stop if no draggable is active
        ) {
            return undefined; //end event, do nothing
        }

        setActiveDress((prev) => {
            if (prev.includes('empty')) {
                return [active.id]
            } else {
                return [...prev, active.id]
            }
        })

        setAllowedButtons(buttons.find(b => b.id === active.id).allowed)
    }

    useEffect(() => {
        // check if it is a complete set without the cape (f1)
        const set = sets[activeDress.join('_').replace('_f1', '')]

        if (set) {
            setBackgroundImage(set.backgroundImage)
            setStartGameComplete(true)
        }
    }, [activeDress])

    const isButtonDisabled = (button) => {
        return !allowedButtons.includes(button.id)
    }

    const props = {
        gameCompleted,
        handleReset,
        gameID,
        handleGameCompleted,
        showCharacter,
        setShowCharacter
    }

    const sensors = useSensors(
        useSensor(MouseSensor),
        useSensor(KeyboardSensor),
        useSensor(TouchSensor)
    );

    return (
        <Transition className={`game game${gameID}`}>
            <DndContext
                modifiers={[restrictToFirstScrollableAncestor]}
                sensors={sensors}
                onDragEnd={handleDragEnd}
            >
                <div className='container'>
                    <div className={classnames('button-container', { 'space': showCharacter })} style={{ backgroundImage: 'url(' + require(`assets/images/game_${gameID}/canvas_${backgroundImage}.jpg`) + ')' }}>
                        <Droppable id='drop'>
                            <CSSTransition
                                in={showCharacter}
                                timeout={400}
                                classNames='fade'
                                unmountOnExit
                            >
                                <div className='stage' style={{ backgroundImage: 'url(' + require(`assets/images/game_${gameID}/dress_background_floor.png`) + ')' }}>
                                    <CrossfadeImage duration={500} src={require(`assets/images/game_${gameID}/dress_${activeDress.join('_')}.png`)} />
                                </div>
                            </CSSTransition>
                        </Droppable>

                        {buttons.map((button, i) => (
                            <div key={i} className='spot'>
                                { !activeDress.includes(button.id) &&
                                    <Draggable
                                        id={button.id}
                                        key={i}
                                        disabled={isButtonDisabled(button)}
                                    >
                                        <img src={require(`assets/images/game_${gameID}/button_item_${button.id}.png`)} />
                                    </Draggable>
                                }
                            </div>
                        ))}
                    </div>
                </div>
            </DndContext>

            { startGameComplete && 
                <button className='complete button' onClick={handleGameCompleted}>
                    {t('general.finish')}<img src={ArrowRight} />
                </button>
            }
            <Navigation charater='button_berta' Help={Help} helpProps={props} />
        </Transition>
    )
}