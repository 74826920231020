import { useState, useMemo, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { DndContext, useSensor, MouseSensor, TouchSensor, KeyboardSensor, useSensors } from '@dnd-kit/core'
import { restrictToFirstScrollableAncestor } from "@dnd-kit/modifiers"

import { SaveContext, CompleteGame } from 'components/save'
import Navigation from 'components/navigation'
import Transition from 'components/navigation/transition'
import Help from './help'
import Modal from './modal'
import { Draggable } from 'components/dnd/draggable'
import { Droppable } from 'components/dnd/droppable'
import './game5.scss'

import random from 'components/functions/random'

export default function Game5() {

    const gameID = 5
    const { save, setSave } = useContext(SaveContext)
    const { t } = useTranslation()

    const [placedCoins, setPlacedCoins] = useState(Array(3).fill(null))
    const [gameCompleted, setGameCompleted] = useState(false)
    const [modalVisible, setModalVisible] = useState(false)
    const [modal, setModal] = useState({title: null, text: null, person: null, gameID: gameID})

    const [coins] = useState([
        { spot: 0, image: 1, person: 'Erzherzog Sigismund' },
        { spot: 1, image: 2, person: 'Kaiser Leopold' },
        { spot: 2, image: 3, person: 'Maximilian Heinrich Herzog von Bayern' }
    ])

    const shuffledCoins = useMemo(() => {
        // Clone coins shuffle on gamecompletion
        return [...coins].sort(() => random() - 0.5)
    }, [gameCompleted])

    const handleReset = () => {
        setPlacedCoins(Array(5).fill(null))
        setGameCompleted(false)
    }

    const handleGameCompleted = () => {
        CompleteGame({ id: gameID, save, setSave })
        setGameCompleted(true)
    }

    const handleDragEnd = (event) => {
        const { active, over } = event

        if (
            over === null || // dropped but not over a dropable
            active === null || // stop if no draggable is active
            over.id !== active.id // stop if dropped on the wrong spot
        ) {
            return //end event, wrong or broken placement
        }

        const spot = parseInt(active.id.split('_')[0])
        handleCorrectPlacement(spot)
    }

    const handleCorrectPlacement = (spot) => {
        setPlacedCoins(placedCoins.map((currentCoin, i) => {
            if (i === spot) {
                return spot
            }
            return currentCoin
        }))

        const person = coins[spot]
        setModal({
            name: t(`games.game${gameID}.objects.${spot}.name`),
            text: t(`games.game${gameID}.objects.${spot}.text`),
            person: person,
            gameID: gameID
        })
        setModalVisible(true)
    }

    const clearModal = () => {
        setModalVisible(false)

        if (placedCoins.filter(e => e === null).length === 0)
            handleGameCompleted()
    }

    const isCoinPlaced = (spot) => {
        return Boolean(placedCoins[spot] === spot)
    }

    const props = {
        gameCompleted,
        handleReset,
        gameID
    }

    const sensors = useSensors(
        useSensor(MouseSensor),
        useSensor(KeyboardSensor),
        useSensor(TouchSensor)
    )

    return (
        <Transition className={`game game${gameID}`}>
            <div className='container'>
                <Modal modal={modal} visible={modalVisible} close={clearModal} />
                <DndContext
                    modifiers={[restrictToFirstScrollableAncestor]}
                    sensors={sensors}
                    onDragEnd={handleDragEnd}
                >
                    <div className='placementArea'>
                        {coins.map((coin, index) => {
                            return (
                                <div key={`drop-${index}`} className='spot'>
                                <img className='person' src={require(`assets/images/game_${gameID}/person_${coin.image}.jpg`)} />
                                    <Droppable id={coin.spot + '_' + coin.image} disabled={isCoinPlaced(coin.spot)}>
                                        <img className='slot' src={require(`assets/images/game_${gameID}/slot.png`)} />
                                        {isCoinPlaced(coin.spot) &&
                                            <img className='placedCoin' src={require(`assets/images/game_${gameID}/coin_${coin.image}.png`)} />
                                        }
                                    </Droppable>
                                </div>
                            )
                        })}
                    </div>
                    <div className='coinContainer'>
                        {shuffledCoins.map((coin, index) => {
                            return (
                                <div key={`drag-${index}`} className='spot'>
                                    {!isCoinPlaced(coin.spot) && (
                                        <Draggable id={coin.spot + '_' + coin.image}>
                                            <img src={require(`assets/images/game_${gameID}/coin_${coin.image}.png`)} alt={coin.image} />
                                        </Draggable>
                                    )}
                                </div>
                            )
                        })}
                    </div>
                </DndContext>
            </div>

            <Navigation charater='button_bert' Help={Help} helpProps={props} />
        </Transition>
    )
}