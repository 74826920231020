import { useState, useContext } from 'react'

import { SaveContext } from 'components/save'
import DynamicLogo from './dynamicLogo'
import './logos.scss'

export default function Logos() {

    const { save } = useContext(SaveContext)

    const [logoColors] = useState(save.game?.[2]?.colors || Array(12).fill(null))

    const logoComplete = () => {
        return (logoColors.filter(e => e === null).length === 0)
    }

    return (
        <div className='logos'>
            { logoComplete() ? <DynamicLogo logoColors={logoColors} /> : <img className='vmLogo' src={require('assets/images/components/logo.png')} /> }
            <img className='vmTitle' src={require('assets/images/components/logo_label.png')} />
            <img className='vlmv' src={require('assets/images/components/logo_verein.png')} />
        </div>
    )
}