import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'

import isDebug from 'components/functions/debug'
import DE from 'assets/locales/de.json'

i18n.use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    //.use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: 'de',
        debug: isDebug(),
        detection: {
            order: ['querystring', 'cookie', 'subdomain'],
            cache: ['cookie'],
        },
        resources: {
            de: {
                translation: DE,
            },
        },
        interpolation: {
            escapeValue: false,
        }
    })

export default i18n