import React from "react"

export function FoundCounter({ found, foundCount, gameID }) {

    return (
        <div className='foundCounter'>
            {Array.from({ length: foundCount }, (_, i) => (
                <div key={i} className='letterContainer'>
                    <img
                        src={require(`assets/images/game_${gameID}/letter_inactive.png`)}
                    />
                    <img
                        className={'letter ' + (i < found ? 'found' : 'missing')}
                        src={require(`assets/images/game_${gameID}/letter_love.png`)}
                    />
                </div>
            ))}
        </div>
    )
}

export default FoundCounter