import React from 'react';
import { useDroppable } from '@dnd-kit/core';

export function Droppable(props) {
    const { isOver, setNodeRef } = useDroppable({
        id: props.id,
        disabled: props.disabled,
    });

    const style = {
        opacity: isOver ? .5 : 1,
    };

    return (
        <div ref={setNodeRef} style={style} className='droppable'>
            {props.children}
        </div>
    );
}