const dynamicLogo = ({ logoColors }) => {

    const possibleColors = [
        '#E30504',
        '#E43B04',
        '#FF7A01',
        '#FEC200',
        '#FFF600',
        '#B3E10D',

        '#8F1D58',
        '#73388A',
        '#4B04E6',
        '#0100E4',
        '#0CC3AD',
        '#00CB00'
    ];

    const logoAreas = [
        'M10 81.08 410 10v54.92L10 136V81.08z',
        'M10 223.08 410 152v54.92L10 278v-54.92z',
        'M10 365.08 410 294v54.92L10 420v-54.92z',
        'M410 64.92 810 136V81.08L410 10v54.92z',
        'M410 206.92 810 278v-54.92L410 152v54.92z',
        'M410 348.92 810 420v-54.92L410 294v54.92z',
        'M10 135.92 410 207v-54.92L10 81v54.92z',
        'M10 277.92 410 349v-54.92L10 223v54.92z',
        'M10 419.92 410 491v-54.92L10 365v54.92z',
        'M410 152.08 810 81v54.92L410 207v-54.92z',
        'M410 294.08 810 223v54.92L410 349v-54.92z',
        'M410 436.08 810 365v54.92L410 491v-54.92z'
    ]

    return (
        <svg className='vmLogo' xmlns='http://www.w3.org/2000/svg' viewBox='0 8 820 486'>
            { logoAreas.map((area, index) => {
                return (
                    <path
                        key={index}
                        fill={isNaN(logoColors[index]) ? '#fff' : possibleColors[logoColors[index]]}
                        stroke='black'
                        strokeWidth='5'
                        strokeLinejoin='bevel'
                        d={area}
                    />
                )
            }) }
        </svg>
    );
}

export default dynamicLogo;