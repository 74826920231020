import { useEffect, useState } from 'react'
import fscreen from 'fscreen'

import ButtonFullscreenOpen from 'assets/vectors/button_fullscreen_open.svg'
import ButtonFullscreenClose from 'assets/vectors/button_fullscreen_close.svg'

const Fullscreen = () => {
    const [fullscreen, setFullscreen] = useState(fscreen.fullscreenElement !== null)

    const fullscreenToggle = () => {
        if (fscreen.fullscreenEnabled) {
            if (fscreen.fullscreenElement) {
                fscreen.exitFullscreen()
            } else {
                fscreen.requestFullscreen(document.documentElement)
            }
        }
    }

    useEffect(() => {
        fscreen.addEventListener('fullscreenchange', () => {
            setFullscreen(fscreen.fullscreenElement)
        })

        return () => fscreen.removeEventListener('fullscreenchange', () => {})
    }, [])

    // Don't show button if fullscreen is not supported
    if (!fscreen.fullscreenEnabled) return null

    return (
        <button className='fullscreen' onClick={fullscreenToggle}>
            <img src={fullscreen ? ButtonFullscreenClose : ButtonFullscreenOpen} />
        </button>
    )
}

export default Fullscreen