import { CSSTransition } from "react-transition-group"
import { ReactComponent as ButtonClose } from 'assets/vectors/button_close.svg'
import './modal.scss'

const ModalLayout = ({ visible, title, close, children }) => {

    return (
        <CSSTransition
            in={visible}
            timeout={300}
            classNames="fade"
            unmountOnExit
        >
            <div className='modalContainer'>
                <div className='modal'>
                    <div className='header'>
                        <h2>{title}</h2>
                        <button className='close' onClick={close}>
                            <ButtonClose />
                        </button>
                    </div>
                    <div className='content'>
                        {children}
                    </div>
                </div>
                <div className='full overlay' onClick={close} />
            </div>
        </CSSTransition>
    )
}

export default ModalLayout