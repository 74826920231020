import { useNavigate } from "react-router-dom"

import Logos from 'components/logos'
import ButtonExit from 'assets/vectors/button_exit.svg'

const Back = (props) => {
    const navigate = useNavigate()
    const { showLogos = false, children } = props

    const goBack = () => {
        navigate('./..', { replace: true })
    }

    const displayLogos = () => {
        return showLogos
    }

    return (
        <>
            { displayLogos() && <Logos /> }
            <div className='navigation'>
                <button className='back' onClick={goBack}>
                    <img src={ButtonExit} />
                </button>
                {children}
            </div>
        </>
    )
}

export default Back