import { useEffect, useState, useContext } from 'react'
import { CSSTransition } from 'react-transition-group'
import classnames from 'classnames'
import { DndContext, useSensor, MouseSensor, TouchSensor, KeyboardSensor, useSensors, closestCenter } from '@dnd-kit/core'
import { arrayMove, SortableContext, sortableKeyboardCoordinates, horizontalListSortingStrategy } from "@dnd-kit/sortable"
import { restrictToHorizontalAxis, restrictToParentElement } from "@dnd-kit/modifiers"

import { SaveContext, CompleteGame } from 'components/save'
import Navigation from 'components/navigation'
import Transition from 'components/navigation/transition'
import Help from './help'
import { Sortable } from './sortable'
import './game9.scss'
import random from 'components/functions/random'

export default function Game9() {

    const gameID = 9
    const { save, setSave } = useContext(SaveContext)

    // create an array with the numbers starting with 1 and parse it to string for the key
    const numbers = Array.from({ length: 6 }, (_, i) => String(i + 1)).sort(() => random() - 0.5)
    const [items, setItems] = useState(numbers)

    const [startGameComplete, setStartGameComplete] = useState(false)
    const [gameCompleted, setGameCompleted] = useState(false)
    const [showGameCompleteImage, setShowGameCompleteImage] = useState(false)

    const handleReset = () => {
        setItems(numbers)
        setGameCompleted(false)
        setShowGameCompleteImage(false)
        setStartGameComplete(false)
    }

    const handleGameCompleted = () => {
        CompleteGame({ id: gameID, save, setSave })
        setStartGameComplete(true)

        setTimeout(() => {
            setShowGameCompleteImage(true)
        }, 800)

        setTimeout(() => {
            setGameCompleted(true)
        }, 4000)
    }

    useEffect(() => {
        // If items are sorted correctly, game is completed
        if (items.join('') === '123456') handleGameCompleted()
    }, [items])

    function handleDragEnd(event) {
        const { active, over } = event

        if (
            over === null || // dropped but not over a dropable
            active === null // stop if no draggable is active
        ) {
            return undefined; //end event, do nothing
        }

        if (active.id !== over.id) {
            setItems((items) => {
                const oldIndex = items.indexOf(active.id)
                const newIndex = items.indexOf(over.id)

                return arrayMove(items, oldIndex, newIndex)
            })
        }
    }

    const props = {
        gameCompleted,
        handleReset,
        gameID
    }

    const sensors = useSensors(
        useSensor(MouseSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        }),
        useSensor(TouchSensor)
    )

    return (
        <Transition className={`game game${gameID}`}>
            <div className='container'>
                <div className={classnames('placementContainer', {'completed': startGameComplete} )}>
                    <div className='placementArea'>
                        <DndContext
                            modifiers={[restrictToHorizontalAxis, restrictToParentElement]}
                            sensors={sensors}
                            collisionDetection={closestCenter}
                            onDragEnd={handleDragEnd}
                        >
                            <SortableContext items={items} strategy={horizontalListSortingStrategy}>
                                {items.map((name, _) => (
                                    <Sortable key={name} index={name} name={name} disabled={startGameComplete} />
                                ))}
                            </SortableContext>
                        </DndContext>
                    </div>
                </div>
            </div>

            <CSSTransition
                in={showGameCompleteImage}
                timeout={750}
                classNames='fade'
            >
                <div className='endGameContainer fade-enter' style={{ transitionDuration: '750ms' }}>
                    <img src={require(`assets/images/game_${gameID}/game_complete.jpg`)} />
                </div>
            </CSSTransition>

            <Navigation charater='button_bert' Help={Help} helpProps={props} />
        </Transition>
    )
}