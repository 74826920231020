import { useState, useRef, useEffect } from 'react'
import { CSSTransition } from 'react-transition-group'
import { useTranslation } from 'react-i18next'

import Media from 'components/media'
import './endscreen.scss'
import Transition from 'components/navigation/transition'
import ButtonExit from 'assets/vectors/button_exit.svg'

export default function EndScreen({ session, setSession, endScreen, setEndScreen }) {

    const { t } = useTranslation()
    const [videoEnded, setVideoEnded] = useState(false)
    const video = useRef(null)

    
    // 0: endScreen is not visible
    // 1: endScreen is open because game is completed
    // 2: endScreen is open because button is clicked

    const close = () => {
        if (endScreen === 1) {
            setSession(session => ({
                ...session,
                endScreen: true
            }))
        }
        setEndScreen(0)
    }

    const videoPlayback = () => {
        if (videoEnded) {
            video.current.currentTime = 0
            setVideoEnded(false)
        }

        if (video.current.paused) {
            video.current.play()
            video.current.parentElement.classList.add('playing')
        } else {
            video.current.pause()
            video.current.parentElement.classList.remove('playing')
        }
    }

    const videoEndedPlayback = () => {
        setVideoEnded(true)
        video.current.currentTime = video.current.duration - 0.1
    }

    return (
        <CSSTransition
            in={endScreen !== 0}
            appear={true}
            classNames='fade'
            timeout={500}
            unmountOnExit={true}
        >
            <Transition className='game endscreen' style={{ backgroundImage: 'url(' + require(`assets/images/map/background.jpg`) + ')' }}>
                <div className='navigation'>
                    <button className='back' onClick={close}>
                        <img src={ButtonExit} />
                    </button>
                </div>
                <Media audio='map_complete' audioFolder='' className='player viewport-button'>
                    <div className='grid'>
                        <div className='text'>
                            <div>
                                {endScreen === 1 && <h1>{t(`pages.complete.title`)}</h1>}
                                <p>{t(`pages.complete.text`)}</p>
                            </div>
                            <img className='book' src={require('assets/images/map/book.png')} />
                        </div>
                        <div className='book-video'>
                            <video poster={require(`assets/videos/caps/book.jpg`)} ref={video} muted preload='auto' onClick={videoPlayback} onEnded={videoEndedPlayback}>
                                <source src={require(`assets/videos/vp8-webm/book.webm`)} type='video/webm' />
                                <source src={require(`assets/videos/book.mp4`)} type='video/mp4' />
                            </video>
                        </div>
                        <div className='book-desktop'>
                            <img src={require('assets/images/map/book.png')} />
                        </div>
                    </div>
                </Media>
            </Transition>
        </CSSTransition>
    )
}