import { useState, useEffect } from 'react'
import axios from 'axios'

export const GetImages = () => {
    const [images, setImages] = useState([])

    useEffect(() => {
        async function fetchData() {
            const result =  await axios({
                url: '/api/images.php',
                responseType: 'json'
            })

            setImages(result.data)
        }
        fetchData()
    }, [])

    return images
}

export default GetImages