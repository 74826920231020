import { useState, useRef } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { CSSTransition } from "react-transition-group"

import ArrowRight from 'assets/vectors/arrow_white_right.svg'
import BadgeCheck from 'assets/vectors/badge_check.svg'
import Loading from 'assets/vectors/button_redo_white.svg'

export const Form = () => {
    const { t } = useTranslation()
    const [mail, setMail] = useState({})
    const [mailStatus, setMailStatus] = useState({ status: 0 })
    const form = useRef(null)

    const handleFormSubmit = (e) => {
        e.preventDefault()

        if (!validateData()) return
        if (mailStatus.loading) return
        setMailStatus(prev => ({ ...prev, loading: true }))

        axios({
            method: 'post',
            url: '/api/mail.php',
            headers: {
                'content-type': 'application/json'
            },
            data: mail
        })
        .then(result => {
            setMailStatus(prev => ({
                ...prev,
                status: result.status,
                response: result.data
            }))
        })
        .catch(error => {
            setMailStatus(prev => ({
                ...prev,
                status: error.response.status,
                response: error.message
            }))
        })
    }

    const validateData = () => {
        if (mail.potter) return false

        if (!mail.name) {
            form.current.querySelector('#name').classList.add('error')
        }

        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (!mail.email || regex.test(mail.email) === false) {
            form.current.querySelector('#email').classList.add('error')
            return false
        }

        if (!mail.message) {
            form.current.querySelector('#message').classList.add('error')
        }

        if (!mail.dsgvo) {
            form.current.querySelector('#dsgvo').parentNode.classList.add('error')
        }

        return (mail.name && mail.email && mail.message && mail.dsgvo)
    }

    const setValue = (e, key) => {
        e.target.classList.remove('error')
        if (e.target.type === 'checkbox') {
            setMail(prev => ({ ...prev, [key]: e.target.checked }))
            return
        }
        setMail(prev => ({ ...prev, [key]: e.target.value }))
    }

    return (
        <div className='main'>
            <div className='pals'>
                <img src={require('assets/images/map/Bert_Berta.png')} />
            </div>

            {mailStatus.status === 0 &&
                <form ref={form}>
                    <div>
                        <label htmlFor="name">{t('pages.post.name.title')}</label>
                        <input type="text" id="name" name="name" required='required'
                            placeholder={t('pages.post.name.placeholder')}
                            onChange={e => setValue(e, 'name')}
                        />
                    </div>

                    <div>
                        <label htmlFor="name">{t('pages.post.email.title')}</label>
                        <input type="email" id="email" name="email" required='required'
                            placeholder={t('pages.post.email.placeholder')}
                            onChange={e => setValue(e, 'email')}
                        />
                    </div>

                    <div className='fill'>
                        <label htmlFor="name">{t('pages.post.message.title')}</label>
                        <textarea id="message" name="message" required='required'
                            placeholder={t('pages.post.message.placeholder')}
                            onChange={e => setValue(e, 'message')}
                        ></textarea>
                    </div>

                    <div className='checkbox'>
                        <input type="checkbox" id="dsgvo" name="dsgvo" required='required'
                            onChange={e => setValue(e, 'dsgvo')}
                        />
                        <label htmlFor="dsgvo">
                            <Trans i18nKey="pages.post.dsgvo.text">
                                <Link style={{textDecoration: 'underline', textShadow: '1px 1px 1px #00000050'}} to={t('routes.privacy.path')} target='_blank' />
                            </Trans>
                        </label>
                    </div>

                    <div className='status'>

                        <input type="text" id="potter" name="potter"
                            placeholder={t('pages.post.name.placeholder')}
                            onChange={e => setValue(e, 'potter')}
                        />
                        <button className='button'
                            onClick={e => handleFormSubmit(e)}>
                            {mailStatus.loading
                                ? <img className='rotate' src={Loading} />
                                : <><span>{t('pages.post.submit')}</span><img src={ArrowRight} /></>
                            }
                        </button>

                    </div>
                </form>
            }

            <CSSTransition
                in={mailStatus.status !== 0}
                timeout={400}
                classNames="fade"
                unmountOnExit
            >
                <div className='sent'>
                    {mailStatus.status === 200
                        ? <><img src={BadgeCheck} /> <span>{t('pages.post.sent')}</span></>
                        : <span>{t('pages.post.error')}</span>
                    }
                </div>
            </CSSTransition>

        </div>
    )
}

export default Form