import { lazy, Suspense } from 'react'
import { useTranslation } from 'react-i18next'

import Back from 'components/navigation/back'
import Transition from 'components/navigation/transition'

export default function LoadStaticHtml(id, path) {
    const { t, i18n } = useTranslation()
    const lang = i18n.language

    const Html = lazy(() => import('../../' + path + lang))

    return (
        <Transition className='game page info' style={{ backgroundImage: 'url(' + require(`assets/images/records/background.jpg`) + ')' }}>
            <Back showLogos={true} />
            <h1>{t(`pages.${id}.title`)}</h1>

            <div className='main'>
                <div className='scroll'>
                    <div className='text'>
                        <Suspense>
                            <Html />
                        </Suspense>
                    </div>
                </div>
            </div>
        </Transition>
    )
}