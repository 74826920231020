import { useState, useEffect, useRef } from 'react'

export default function Sheet(props) {

    const { gameID, Music, playedNote, setPlayedNote, currentNoteId, setCurrentNoteId, setStartGameComplete } = props
    const bpm = Music.header.bpm
    const beat = 4
    const spacing = bpm * 2
    const music = Music.tracks[1].notes
    const initialTime = music[0].time

    const staffRef = useRef()
    const [currentNote, setCurrentNote] = useState(Music.tracks[1].notes[0])

    // Read Pressed Key and set play if the note is correct
    useEffect(() => {
        if (!playedNote) return
        if (playedNote.note == currentNote.name) {
            setCurrentNoteId((currentNoteId) => currentNoteId + 1)
            fadeOutNote(staffRef.current.children[currentNoteId])
        }
        setPlayedNote(null)
    }, [playedNote])

    useEffect(() => {
        const nextNote = music[currentNoteId]
        let newPos = nextNote ? ((nextNote.time - initialTime) * -spacing) : 0

        staffRef.current.style.transform = `translateX(${newPos}px)`

        if (!nextNote) {
            staffRef.current.style.transitionDuration = `0ms`
            setCurrentNoteId(0)
            setStartGameComplete(true)
            return
        }

        staffRef.current.style.transitionDuration = `${(nextNote.time - currentNote.time) * 1000}ms`

        setCurrentNote(music[currentNoteId])
    }, [currentNoteId])

    const fadeOutNote = (note) => {
        note.classList.add('fade')
        setTimeout(() => {
            note.classList.remove('fade')
        }, 1000)
    }

    const getBeat = (index) => {
        const currentTime = music[index].time
        const nextTime = music[index + 1] ? music[index + 1].time : 0
        let beatTime = Math.round((nextTime - currentTime) * beat)
        if (beatTime < 0) beatTime = Math.round(music[index].duration * beat)

        return `beat_${beatTime}-${beat}`
    }

    const getBeatSpans = () => {
        const beatSpans = Array.from({ length: Math.ceil(Music.duration) }, (_, i) => i)
        return (
            <div className="beatSpans">
                {beatSpans.map((_, i) => <div key={i}></div>)}
            </div>
        )
    }

    return (
        <div className='sheet'>
            <img className='clef' src={require(`assets/images/game_${gameID}/symbol.png`)} />
            <div className='staffContainer'>
                <div className='current' />
                <div className='staff' ref={staffRef}>
                    {music && music.map((note, index) => (
                        <svg className={`note-${note.name} ${getBeat(index)}`} key={index}  viewBox="0 0 28 95" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="25" width="3" height="73" rx="1.5"/>
                            <ellipse stroke-width="3" cx="14.5" cy="72" rx="12" ry="9"/>
                        </svg>
                    ))}
                    {getBeatSpans()}
                </div>
            </div>

            <div className='ledgerLines'>
                <div />
                <div />
                <div />
                <div />
                <div />
            </div>
        </div>
    )
}