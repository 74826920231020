import { useState, useEffect } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { useTranslation } from 'react-i18next'
import CookieConsent, { getCookieConsentValue, resetCookieConsentValue } from 'react-cookie-consent'

import Save from 'components/save'
import isDebug from 'components/functions/debug'

import Intro from './pages/intro'
import Records from './pages/records'
import Gallery from './pages/gallery'
import Mail from './pages/mail'
import Interviews from './pages/interviews'
import Map from './pages/map'
import Imprint from './pages/imprint'
import Privacy from './pages/privacy'

import Game1 from './games/game1'
import Game2 from './games/game2'
// import Game3 from './games/game3'
import Game4 from './games/game4'
import Game5 from './games/game5'
import Game6 from './games/game6'
import Game7 from './games/game7'
import Game8 from './games/game8'
import Game9 from './games/game9'
import Game10 from './games/game10'
import Game11 from './games/game11'

export default function PageRoutes() {

    const location = useLocation()
    const { t } = useTranslation()
    const [cookieConsent, setCookieConsent] = useState(getCookieConsentValue() === 'true')
    const [cookieConsentIsSet, setCookieConsentIsSet] = useState(false)

    useEffect(() => {
        var _paq = window._paq = window._paq || [];

        _paq.push(['enableLinkTracking']);
        _paq.push(['trackPageView']);
        (function() {
            var u="https://matomo.kulturhaeuser.at/";
            _paq.push(['setTrackerUrl', u+'matomo.php']);
            _paq.push(['setSiteId', '4']);
            _paq.push(['requireCookieConsent']);
            var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
            g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
        })();
    }, [])

    useEffect(() => {
        var _paq = window._paq = window._paq || [];

        if (cookieConsent) {
            _paq.push(['setCookieConsentGiven']);
            setCookieConsentIsSet(true)
        } else {
            _paq.push(['forgetCookieConsentGiven']);
            resetCookieConsentValue()
        }
    }, [cookieConsent])

    useEffect(() => {
        const currentPath = location.pathname
        const routes = GetRoutes()

        const mainTitle = routes[0].title
        const title = routes.find(route => route.path === currentPath)?.title

        document.title = title ? `${title} | ${mainTitle}` : mainTitle
        // set page keywords
        const keywords = routes.find(route => route.path === currentPath)?.keywords
        document.querySelector('meta[name="keywords"]').setAttribute('content', keywords)
        // set page description
        const description = routes.find(route => route.path === currentPath)?.description
        document.querySelector('meta[name="description"]').setAttribute('content', description)

        var _paq = window._paq = window._paq || [];
        _paq.push(['setCustomUrl', currentPath]);
        _paq.push(['setDocumentTitle', document.title]);
        _paq.push(['trackPageView']);
    }, [location])

    const GetRoutes = () => {
        const routes = [
            { id: 'intro', component: <Intro /> },
            { id: 'records', component: <Records /> },
            { id: 'gallery', component: <Gallery /> },
            { id: 'mail', component: <Mail /> },
            { id: 'interviews', component: <Interviews /> },
            { id: 'imprint', component: <Imprint /> },
            { id: 'privacy', component: <Privacy /> },
            { id: 'map', component: <Map /> },
            { id: 'game1', component: <Game1 /> },
            { id: 'game2', component: <Game2 /> },
            // { id: 'game3', component: <Game3 /> },
            { id: 'game4', component: <Game4 /> },
            { id: 'game5', component: <Game5 /> },
            { id: 'game6', component: <Game6 /> },
            { id: 'game7', component: <Game7 /> },
            { id: 'game8', component: <Game8 /> },
            { id: 'game9', component: <Game9 /> },
            { id: 'game10', component: <Game10 /> },
            { id: 'game11', component: <Game11 /> },
        ]

        return routes.map(route => {
            route.path = t(`routes.${route.id}.path`)
            route.title = t(`routes.${route.id}.title`)
            route.keywords = t(`routes.${route.id}.keywords`)
            route.description = t(`routes.${route.id}.description`)

            return route
        })
    }

    return (
        <Save cookieConsentIsSet={cookieConsentIsSet}>
            <TransitionGroup component={null}>
                <CSSTransition key={location.key} classNames="page" timeout={500}>
                    <Routes location={location}>
                        {GetRoutes().map(route => (
                            <Route key={route.id} path={route.path} element={route.component} title={route.title} />
                        ))}

                        { !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ?
                            <Route path="*" element={<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}><div style={{fontSize: '60px', textAlign: 'center', textShadow: '3px 3px 5px #00000033'}}>Not implemented yet</div></div>} /> :
                            <Route path="*" element={<Intro />} />
                        }
                    </Routes>
                </CSSTransition>
            </TransitionGroup>
            <CookieConsent
                location="none"
                overlay={true}
                overlayStyle={{zIndex: '1000'}}
                overlayClasses="full overlay"
                enableDeclineButton={true}
                buttonText={t('general.cookie.agree')}
                declineButtonText={t('general.cookie.decline')}
                disableStyles={true}
                contentClasses='content'
                buttonWrapperClasses='button-wrapper'
                buttonClasses='button'
                declineButtonClasses='button decline'
                onAccept={() => setCookieConsent(true) }
                onDecline={() => setCookieConsent(false) }
            >
                {t('general.cookie.text')}
                <a href='https://www.vorarlbergmuseum.at/datenschutz' target='_blank'>
                    {t('general.cookie.more')}
                </a>
            </CookieConsent>
        </Save>
    )
}