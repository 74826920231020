
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { resetCookieConsentValue } from 'react-cookie-consent'
import fscreen from 'fscreen'

import "./intro.scss"
import Logos from 'components/logos'
import Transition from 'components/navigation/transition'

import Media from 'components/media'
import useWindowDimensions from 'components/hooks/useWindowDimensions'

function Intro() {
    const { t } = useTranslation()
    const { width } = useWindowDimensions()
    const switchValue = 650

    const [type, setType] = useState(width > switchValue ? 'desktop' : 'mobile')

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang)
    }

    useEffect(() => {
        const newType = width > switchValue ? 'desktop' : 'mobile'
        setType(newType)
    }, [width])

    const openCookieConsent = (e) => {
        e.preventDefault()
        resetCookieConsentValue()
        // Rerender the page to show the cookie consent
        window.location.reload()
    }

    const openFullscreen = () => {
        // is mobile & fullscreen is supported & fullscreen is not active
        if (type === 'mobile' && fscreen.fullscreenEnabled && fscreen.fullscreenElement === null) {
            fscreen.requestFullscreen(document.documentElement)
        }
    }

    const closeFullscreen = () => {
        // is mobile & fullscreen is supported & fullscreen is active
        if (type === 'mobile' && fscreen.fullscreenEnabled && fscreen.fullscreenElement !== null) {
            fscreen.exitFullscreen()
        }
    }

    const Buttons = () => {
        const buttons = ['map', 'records', 'gallery', 'mail', 'interviews']

        return buttons.map(button => {
            const path = t(`routes.${button}.path`)
            const title = t(`routes.${button}.title`)
            const icon = require(`assets/images/intro/${button}.png`)
            const state = button === 'map' ? { hasTransition: true } : {}
            const click = button === 'mail' ? closeFullscreen : openFullscreen

                return (
                    <Link to={path} key={button} onClick={click} state={state}>
                        <img src={icon} alt={title} />
                        <div className='title'>{title}</div>
                    </Link>
                )
        })
    }

    return (
        <Transition className={`intro ${type}`}>
            <Logos />
            <Media audio={`intro-music`} audioFolder='' audioLoop={true} />
            <video className='background-video' key={type} autoPlay loop muted poster={require(`assets/videos/caps/home_video_${type}.jpg`)}>
                <source src={require(`assets/videos/vp8-webm/home_video_${type}.webm`)} type='video/webm' />
                <source src={require(`assets/videos/home_video_${type}.mp4`)} type='video/mp4' />
            </video>

            <div className='bottom'>
                <div className='buttons'>
                    <Buttons />
                </div>

                <div className='footer'>
                    <div className='links'>
                        <span>
                            © {new Date().getFullYear()} {t('pages.intro.footer.copyright')}
                        </span>
                        <Link to={t('routes.imprint.path')}>
                            {t('routes.imprint.title')}
                        </Link>
                        <Link to={t('routes.privacy.path')}>
                            {t('routes.privacy.title')}
                        </Link>
                        <a href='#' onClick={openCookieConsent}>
                            {t('pages.intro.footer.cookies')}
                        </a>
                    </div>
                    <div className='info'>
                        <a className='button' href={t('pages.intro.footer.museumLink')} target='_blank'>
                            {t('pages.intro.footer.museum')}
                        </a>
                    </div>
                </div>
            </div>
        </Transition>
    )
}

export default Intro