import { useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Media from 'components/media';
import ArrowRight from 'assets/vectors/arrow_white_right.svg';
import Restart from 'assets/vectors/button_redo.svg';
import BubbleArrow from 'assets/vectors/bubble_arrow_3.svg';

const Help = ({ helpVisible, setHelpVisible, props }) => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { gameCompleted, handleReset, gameID } = props;

    const hideHelp = () => {
        setHelpVisible(false);
    };

    useEffect(() => {
        if (gameCompleted) {
            setHelpVisible(true);
        }
    }, [gameCompleted]);

    const nextGame = () => {
        navigate('./..', { replace: true })
    };

    const resetGame = () => {
        handleReset();
    };

    return (
        <CSSTransition
            in={helpVisible}
            timeout={300}
            classNames="fade"
        >
            <div className='helpBoxContainer'>
                { gameCompleted ?
                    <div className='helpBox'>
                        <Media audio={`game_${gameID}_win`} video={`bert_default_winner`} videoLoop={false}>
                            <p>{t(`games.game${gameID}.win`)}</p>
                            <img className='arrow' src={BubbleArrow} />
                        </Media>
                        <button className='button' onClick={nextGame}>
                            {t('general.nextGame')}<img src={ArrowRight} />
                        </button>
                        <button className='button white' onClick={resetGame}>
                            {t('general.replay')}<img className='rotate' src={Restart} />
                        </button>
                    </div>
                :
                    <div className='helpBox'>
                        <Media audio={`game_${gameID}_help`} video={`game_${gameID}_help`}>
                            <p>{t(`games.game${gameID}.help`)}</p>
                            <img className='arrow left' src={BubbleArrow} />
                        </Media>
                        <button className='button' onClick={hideHelp}>
                            {t('general.start')}<img src={ArrowRight} />
                        </button>
                    </div>
                }
                <div className='overlay' onClick={gameCompleted ? undefined : hideHelp} />
            </div>
        </CSSTransition>
    );
};

export default Help;