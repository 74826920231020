import React, { Suspense, StrictMode } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { createRoot } from 'react-dom/client'
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"

import Routes from './Routes'

import isDebug from 'components/functions/debug'
import OldBrowser from 'components/functions/oldBrowser'
import Fallback from 'components/navigation/fallback'
import 'assets/fonts/fonts.css'
import './index.scss'
import './animations.scss'
import './i18n'

if (!isDebug()) {
    const pjson = require('../package.json')
    Sentry.init({
        dsn: "https://e6885e21e64c81e3be508b938007e614@sentry.dev-host.at/11",
        release: "kinderseite@" + pjson.version,
        integrations: [new BrowserTracing(),new Sentry.Replay()],
        ignoreErrors: [/NotAllowedError/, /AbortError/, /NetworkError/],
        // beforeSend(event, hint) {
        //     // Check if it is an exception, and if so, show the report dialog
        //     if (event.exception) {
        //         Sentry.showReportDialog({ eventId: event.event_id })
        //     }
        //     return event
        // },
        tracesSampleRate: 0.2,
        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: 0.1,
        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,
    })
}

const container = document.getElementById('root')
const root = createRoot(container)
root.render(<App />)

const appHeight = () => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`)
}

window.addEventListener('resize', appHeight)
appHeight()

window.addEventListener("unhandledrejection", (event) => {
    // notifiy Sentry of the error
    Sentry.captureException(event.reason)
    // prevent the default error handling
    event.preventDefault()
});

function App() {
    return (
        <StrictMode>
            <BrowserRouter>
                <Suspense fallback={<Fallback />}>
                    <OldBrowser />
                    <Routes />
                </Suspense>
            </BrowserRouter>
        </StrictMode>
    )
}