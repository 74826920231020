import React from 'react'
import { useTranslation, Trans } from 'react-i18next'

import { Pagination, EffectCards, Keyboard, Mousewheel, A11y } from "swiper"
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import "swiper/css/effect-cards"

import useWindowDimensions from 'components/hooks/useWindowDimensions'

export const RecordEntries = ({ setRecord }) => {
    
    const { width } = useWindowDimensions()
    const { t } = useTranslation()
    const transPath = 'pages.records.entries.'
    let records = []

    for (let i = 1; i <= 8; i++) {
        const path = transPath + i + '.'

        records.push({
            id: i,
            type: t(`${path}type`),
            details: t(`${path}details`),
            title: t(`${path}title`)
        })
    }

    return (
        <Swiper
            className='main'
            slidesPerView={"auto"}
            autoHeight={true}
            spaceBetween={120}
            centeredSlides={true}
            grabCursor={true}
            onSlideChange={(swiper) => setRecord(swiper.activeIndex + 1)}
            pagination={{
                type: 'progressbar',
                clickable: true
            }}
            effect={width <= 768 ? 'cards' : 'slide'}
            modules={[ Pagination, EffectCards, Keyboard, Mousewheel, A11y]}
        >
            {records.map(record => (
                <SwiperSlide key={record.id}>
                    <figure>
                        <img src={require(`assets/images/records/${record.id}.jpg`)} alt={record.title} />
                        <figcaption>
                            <div className='type'>
                                    <div>{record.type}</div>
                                    <img src={require(`assets/images/components/logo_combined.png`)} />
                                </div>
                            <div className='details'>{record.details}</div>
                            <div className='description'>
                                <h2>{record.title}</h2>
                                <p><Trans i18nKey={`${transPath}${record.id}.description`} /></p>
                            </div>
                        </figcaption>
                    </figure>
                </SwiperSlide>
            ))}
        </Swiper>
    )
}

export default RecordEntries