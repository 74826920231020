import { useState, useEffect, useContext } from 'react'
import { DndContext, useSensor, MouseSensor, TouchSensor, KeyboardSensor, useSensors } from '@dnd-kit/core'
import { restrictToFirstScrollableAncestor } from "@dnd-kit/modifiers"

import { SaveContext, CompleteGame } from 'components/save'
import Navigation from 'components/navigation'
import Transition from 'components/navigation/transition'
import Help from './help'
import { Draggable } from 'components/dnd/draggable'
import { Droppable } from 'components/dnd/droppable'
import './game11.scss'

import useWindowDimensions from 'components/hooks/useWindowDimensions'
import shuffle from 'components/functions/shuffle'

export default function Game11() {

    const gameID = 11
    const { save, setSave } = useContext(SaveContext)
    const { height, width } = useWindowDimensions()

    const [windows, setWindows] = useState(Array(5).fill(null))
    const [shuffledWindows, setShuffledWindows] = useState(shuffle(Array.from({ length: 5 }, (_, i) => i + 1)))
    const [gameCompleted, setGameCompleted] = useState(false)
    const [startGameComplete, setStartGameComplete] = useState(false)
    const [css, setCss] = useState('')

    const handleReset = () => {
        setWindows(Array(5).fill(null))
        setShuffledWindows(shuffle(Array.from({ length: 5 }, (_, i) => i + 1)))
        setGameCompleted(false)
        setStartGameComplete(false)
    }

    const handleGameCompleted = () => {
        CompleteGame({ id: gameID, save, setSave })
        
        setStartGameComplete(true)

        setTimeout(() => {
            setGameCompleted(true)
        }, 2000)
    }

    useEffect(() => {
        // Width multiplied with 4 and height multiplied with 3 to compensate for the aspect ratio
        setCss(`.game.game${gameID} .placementArea {
                ${width * 3 < height * 4 ? 'width: 100%' : 'height: 100%'}
            }`)
    }, [height, width])

    useEffect(() => {
        // check if all windows are placed
        if (windows.every(w => w !== null)) {
            handleGameCompleted()
        }
    }, [windows])

    const handleDragEnd = (event) => {
        const { active, over } = event

        if (
            over === null || // dropped but not over a dropable
            active === null || // stop if no draggable is active
            over.id !== active.id // stop if dropped on the wrong spot
        ) {
            return //end event, wrong or broken placement
        }

        handleCorrectPlacement(over.id)
    }

    const handleCorrectPlacement = (spot) => {
        setWindows((prev) => {
            const newWindows = [...prev]
            newWindows[spot - 1] = spot
            return newWindows
        })
    }

    const isPlaced = (spot) => {
        return Boolean(windows[spot] !== null)
    }

    const getImageName = (index, placed) => {
        return (isPlaced(index) ? `${placed}.jpg` : 'placeholder.png')
    }

    const props = {
        gameCompleted,
        handleReset,
        gameID
    }

    const sensors = useSensors(
        useSensor(MouseSensor),
        useSensor(KeyboardSensor),
        useSensor(TouchSensor)
    )

    return (
        <Transition className={`game game${gameID}`}>
            <style scoped>{css}</style>
            <div className='container'>
                <DndContext
                    modifiers={[restrictToFirstScrollableAncestor]}
                    sensors={sensors}
                    onDragEnd={handleDragEnd}
                >
                    <div className='placementArea'>
                        <div className='spots'>
                            {windows.map((placed, index) => {
                                return (
                                    <div key={index} className='spot'>
                                        <Droppable id={index + 1} disabled={isPlaced(index)}>
                                            <img src={require(`assets/images/game_${gameID}/window_${getImageName(index, placed)}`)} />
                                        </Droppable>
                                    </div>
                                )
                            })}
                        </div>
                        { startGameComplete && <img className='duck' src={require(`assets/images/game_${gameID}/duck.png`)} /> }
                        <img className='wall' src={require(`assets/images/game_${gameID}/overlay_1.png`)} />
                    </div>
                    <div className='dragContainer'>
                        {shuffledWindows.map(spot => {
                            return (
                                <div key={spot} className='spot'>
                                    {!isPlaced(spot - 1) && (
                                        <Draggable id={spot}>
                                            <img src={require(`assets/images/game_${gameID}/window_${spot}.jpg`)} />
                                        </Draggable>
                                    )}
                                </div>
                            )
                        })}
                    </div>
                </DndContext>
            </div>

            <Navigation charater='button_bert' Help={Help} helpProps={props} />
        </Transition>
    )
}