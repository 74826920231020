import { useState, useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import useOnLoadMedia from 'components/hooks/useOnLoadMedia';

export const Transition = (({ children, ...props }) => {

    const wrapperRef = useRef();
    const loaded = useOnLoadMedia(wrapperRef);
    const [show, setShow] = useState(!loaded);

    const duration = 750;

    useEffect(() => {
        if (loaded)
            setTimeout(() => {
                setShow(false);
            }, duration);
    }, [loaded]);


    return (
            <div {...props} ref={wrapperRef}>
                {children}
                { show &&
                    <CSSTransition
                        timeout={duration}
                        in={!loaded}
                        appear={true}
                        classNames='trans-zoom'
                    >
                        <div className='transition' style={{backgroundImage:'url('+require(`assets/images/intro/transition.png`)+')'}} />
                    </CSSTransition>
                }
            </div>
    );
});

export default Transition;