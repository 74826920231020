import React from 'react'
import { useTranslation, Trans } from 'react-i18next'

import { Pagination, EffectCards, Keyboard, Mousewheel, A11y } from "swiper"
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import "swiper/css/effect-cards"

import useWindowDimensions from 'components/hooks/useWindowDimensions'

export const InterviewEntries = ({ setCurrent }) => {
    
    const { width } = useWindowDimensions()
    const { t } = useTranslation()
    const transPath = 'pages.interviews.entries.'
    let interview = []

    for (let i = 1; i <= 4; i++) {
        const path = transPath + i + '.'

        interview.push({
            id: i,
            name: t(`${path}name`),
            object: t(`${path}object`),
            text: t(`${path}text`)
        })
    }

    return (
        <Swiper
            className='main'
            slidesPerView={"auto"}
            autoHeight={true}
            spaceBetween={120}
            centeredSlides={true}
            grabCursor={true}
            onSlideChange={(swiper) => setCurrent(swiper.activeIndex + 1)}
            pagination={{
                type: 'progressbar',
                clickable: true
            }}
            effect={width <= 768 ? 'cards' : 'slide'}
            modules={[Pagination, EffectCards, Keyboard, Mousewheel, A11y]}
        >
            {interview.map(interview => (
                <SwiperSlide key={interview.id}>
                    <figure>
                        <img src={require(`assets/images/interviews/${interview.id}.jpg`)} alt={interview.title} />
                        <figcaption>
                            <div className='type'>
                                {interview.name}
                            </div>
                            <div className='details'>{interview.object}</div>
                            <div className='description'>
                                <p><Trans i18nKey={`${transPath}${interview.id}.text`} /></p>
                            </div>
                        </figcaption>
                    </figure>
                </SwiperSlide>
            ))}
        </Swiper>
    )
}

export default InterviewEntries