import { useEffect, useState, useContext } from 'react'
import { CSSTransition } from 'react-transition-group'
import classnames from 'classnames'
import CrossfadeImage from "react-crossfade-image"

import { SaveContext, CompleteGame } from 'components/save'
import Navigation from 'components/navigation'
import Transition from 'components/navigation/transition'
import Help from './help'
import Modal from './modal';
import './game7.scss'

import UncoveredInfo from './uncovered'
import { ReactComponent as Checkmark } from 'assets/vectors/badge_check.svg'
import useWindowDimensions from 'components/hooks/useWindowDimensions';
import shuffle from 'components/functions/shuffle';

export default function Game7() {

    const gameID = 7
    const { save, setSave } = useContext(SaveContext)
    const { width } = useWindowDimensions()

    const maxDepth = 5
    const digObject = [
        { id:  1, object: 0, uncovered: false, depth: 0 },
        { id:  2, object: 0, uncovered: false, depth: 0 },
        { id:  3, object: 0, uncovered: false, depth: 0 },
        { id:  4, object: 0, uncovered: false, depth: 0 },
        { id:  5, object: 0, uncovered: false, depth: 0 },
        { id:  6, object: 0, uncovered: false, depth: 0 },
        { id:  7, object: 0, uncovered: false, depth: 0 },
        { id:  8, object: 0, uncovered: false, depth: 0 },
        { id:  9, object: 0, uncovered: false, depth: 0 },
        { id: 10, object: 1, uncovered: false, depth: 0 },
        { id: 11, object: 2, uncovered: false, depth: 0 },
        { id: 12, object: 3, uncovered: false, depth: 0 }
    ]

    const [digs, setDigs] = useState(shuffle([...digObject]))
    const [foundObjectID, setFoundObjectID] = useState(-1) // Current found item index
    const [digging, setDigging] = useState(-1) // Current digging index

    const [startGameComplete, setStartGameComplete] = useState(false)
    const [gameCompleted, setGameCompleted] = useState(false)

    const handleReset = () => {
        setStartGameComplete(false)
        setGameCompleted(false)
        resetDigs()
    }

    const handleGameCompleted = () => {
        CompleteGame({ id: gameID, save, setSave })
        setStartGameComplete(true)
    }

    const resetDigs = () => {
        // Reset Dig depth for new round
        setDigs(prev => prev.map(dig => ({ ...dig, depth: 0, uncovered: false })))

        // Update dig positions delayed for animation
        setTimeout(() => {
            setDigs(shuffle([...digObject]))
        }, 500)
    }

    const updateDig = (index) => {
        // Stop if currently digging another hole
        if (digging !== -1) return
        if (digs[index].uncovered) return

        // Set digging value and que unset
        setDigging(index)
        setTimeout(() => {
            setDigging(-1)
        }, 500)

        setDigs((digs) => [
            ...digs.slice(0, index),
            {
                ...digs[index],
                uncovered: digs[index].depth == maxDepth ? true : false,
                depth: digs[index].depth >= maxDepth ? maxDepth : digs[index].depth + 1
            },
            ...digs.slice(index + 1)
        ])
    }

    useEffect(() => {
        if (digging === -1) return
        if (digs?.[digging] === undefined) return

        if (digs[digging]?.uncovered) setFoundObjectID(digs[digging].object)
    }, [digs, digging])

    const closeModal = () => {
        setGameCompleted(true)
        setStartGameComplete(false)

        // Uncover all objects every 500ms until all of them is depth maxDepth
        for (let i = 0; i < maxDepth; i++) {
            setTimeout(() => {
                setDigs(prev => prev.map(dig => {
                    const nextDepth = dig.depth + 1
                    const depth = nextDepth < maxDepth ? nextDepth : maxDepth
                    const uncovered = depth === maxDepth ? true : dig.uncovered
                    return { 
                        ...dig,
                        uncovered,
                        depth
                    }
                }))
            }, i * 500)
        }
    }

    const getFramePosition = (i) => {
        // frame_top_first,frame_top,frame_bottom_first,frame_bottom
        const corner = width > 768 ? [4, 4, 8] : [3, 6, 9]
        if (i == 0 || i == corner[0] || i == corner[1]) {
            return 'frame_top_first'
        } else if (i == corner[2]) {
            return 'frame_bottom_first'
        } else if (i > corner[2] && i <= 11) {
            return 'frame_bottom'
        }
        return 'frame_top'
    }

    const props = {
        gameCompleted,
        handleReset,
        gameID,
        startGameComplete,
        handleGameCompleted
    }

    return (
        <Transition className={`game game${gameID}`} style={{backgroundImage:'url('+require(`assets/images/game_${gameID}/background.jpg`)+')'}}>
            <div className='container'>
                <div className='digSiteContainer'>
                    <div className='digSite'>
                        {digs.map((dig, i) => (
                            <div className={classnames('digContainer', {'uncovered': dig.uncovered})} key={i} onClick={() => updateDig(i)}>
                                <div className='dig'>
                                    <CrossfadeImage className='digState' duration={500} src={require(`assets/images/game_${gameID}/hole_0${dig.depth}.png`)} />
                                    <CSSTransition
                                        in={dig.uncovered}
                                        timeout={500}
                                        classNames='fade'
                                        unmountOnExit
                                    >
                                        <div className={classnames('digged fade-enter duration-500', {'wrong': dig.object === 0})}>
                                            <div className='objectContainer'>
                                                <img className='object' src={require(`assets/images/game_${gameID}/object_${dig.object}.jpg`)} />
                                                { dig.object !== 0 && <Checkmark /> }
                                            </div>
                                            <img src={require(`assets/images/game_${gameID}/uncovered.png`)} />
                                        </div>
                                    </CSSTransition>
                                    <img className='frame' src={require(`assets/images/game_${gameID}/${getFramePosition(i)}.png`)} />
                                    { (dig.depth > 0 && !dig.uncovered) &&
                                        <img className={classnames('shovel', {'digging': digging == i})} src={require(`assets/images/game_${gameID}/shovel.png`)} />
                                    }
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <Modal gameID={gameID} visible={startGameComplete} close={closeModal} />
            <UncoveredInfo props={props} digs={digs} foundObjectID={foundObjectID} />
            <Navigation charater='button_bert' Help={Help} helpProps={props} />
        </Transition>
    )
}