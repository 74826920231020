import { useContext, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import { SaveContext, SessionContext, CompleteGame } from 'components/save'
import Navigation from 'components/navigation'
import Transition from 'components/navigation/transition'
import './map.scss'
import Help from './help'
import EndScreen from './endScreen'

export default function Map() {
    const { save, setSave } = useContext(SaveContext)
    const { session, setSession } = useContext(SessionContext)
    const { t } = useTranslation()

    const [endScreen, setEndScreen] = useState(0)

    // Generate array for 11 games
    const gameCount = 11
    const completedGames = save.completedGames ?? []

    const helpVisible = session.helpVisible ?? true

    useEffect(() => {
        CompleteGame({ id: 3, save, setSave })
        if (completedGames.length === gameCount && session.endScreen !== true) {
            setTimeout(() => {
                setEndScreen(1)
            }, 3000)
        }
    }, [completedGames])

    const displayEndScreen = (e) => {
        e.preventDefault()
        setEndScreen(2)
    }

    const helpProps = {
        session, 
        setSession
    }

    const GameList = ()  => {
        let gameButton = []

        for (let i = 1; i <= gameCount; i++) {
            // skip Game 3; game is not implemented
            if (i === 3) {
                continue
            }

            const path = t(`routes.game${i}.path`)
            const title = t(`routes.game${i}.title`)

            gameButton.push(
                <Link key={i} to={path} title={title} className={classnames(`game_${i} center`, {
                    'completed': completedGames.includes(i)
                })}>
                    {completedGames.includes(i) ? 
                        <img src={require(`assets/images/map/game_${i}_done.png`)} /> :
                        <img src={require(`assets/images/map/bloom_symbol_${i}.png`)} />
                    }
                </Link>
            )
        }

        return gameButton
    }

    return (
        <>
            <Transition className='game map hasLogo' style={{ backgroundImage: 'url(' + require(`assets/images/map/background.jpg`) + ')' }}>
                <Navigation showLogos={true} Help={Help} helpDefault={helpVisible} helpProps={helpProps} />
                <div className='grid'>
                    <a href='#' className='vm-logo' onClick={displayEndScreen}>
                        <img src={require('assets/images/components/logo_combined.png')} />
                    </a>
                    <div className='map-grid' style={{ backgroundImage: 'url(' + require(`assets/images/map/background_grid.png`) + ')' }}>
                        <GameList />
                    </div>
                </div>
            </Transition>
            <EndScreen 
                session={session}
                setSession={setSession}
                setEndScreen={setEndScreen}
                endScreen={endScreen}
            />
        </>
    )
}