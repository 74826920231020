import { useEffect, useState, useMemo, useContext } from 'react'
import { CSSTransition } from 'react-transition-group'
import { DndContext, useSensor, MouseSensor, TouchSensor, KeyboardSensor, useSensors } from '@dnd-kit/core'
import { restrictToFirstScrollableAncestor } from "@dnd-kit/modifiers"

import { SaveContext, CompleteGame } from 'components/save'
import Navigation from 'components/navigation'
import Transition from 'components/navigation/transition'
import Help from './help'
import { Draggable } from 'components/dnd/draggable'
import { Droppable } from 'components/dnd/droppable'
import './game1.scss'

import useWindowDimensions from 'components/hooks/useWindowDimensions'
import random from 'components/functions/random'

export default function Game1() {

    const gameID = 1
    const { save, setSave } = useContext(SaveContext)
    const { height, width } = useWindowDimensions()

    const [placedLetters, setPlacedLetters] = useState(Array(5).fill(null))
    const [gameCompleted, setGameCompleted] = useState(false)
    const [showGameCompleteImage, setShowGameCompleteImage] = useState(false)
    const [css, setCss] = useState('')

    const [letters] = useState([
        { spot: 0, letter: 'o' },
        { spot: 1, letter: 'a' },
        { spot: 2, letter: 'l' },
        { spot: 3, letter: 'e' },
        { spot: 4, letter: 'g' }
    ])

    const shuffledLetters = useMemo(() => {
        // Clone letters shuffle on gamecompletion
        return [...letters].sort(() => random() - 0.5)
    }, [gameCompleted])

    const handleReset = () => {
        setPlacedLetters(Array(5).fill(null))
        setGameCompleted(false)
        setShowGameCompleteImage(false)
    }

    const handleGameCompleted = () => {
        CompleteGame({ id: gameID, save, setSave })

        setTimeout(() => {
            setShowGameCompleteImage(true)
        }, 1500)

        setTimeout(() => {
            setGameCompleted(true)
        }, 4000)
    }

    useEffect(() => {
        // Width multiplied with 4 and height multiplied with 3 to compensate for the aspect ratio
        setCss(`
        .game.game${gameID} .placementArea {
            background-image: url(`+ require(`assets/images/game_${gameID}/${width > 768 ? 'background.jpg' : 'background_mobile.png'}`) + `);
            ${width * 3 < height * 4 ? 'width: 100%;' : 'height: 100%;'}
        }`);
    }, [height, width])

    useEffect(() => {
        if (placedLetters.filter(e => e === null).length === 0) handleGameCompleted()
    }, [placedLetters])

    const handleDragEnd = (event) => {
        const { active, over } = event

        if (
            over === null || // dropped but not over a dropable
            active === null || // stop if no draggable is active
            //placedLetters[over.id] !== null || // stop if space is occupied
            over.id !== active.id // stop if dropped on the wrong spot
        ) {
            return undefined //end event, do nothing
        }

        const spot = parseInt(active.id.split('_')[0])

        setPlacedLetters(placedLetters.map((currentLetter, i) => {
            if (i === spot) {
                return spot
            }
            return currentLetter
        }))
    }

    const isLetterPlaced = (spot) => {
        return Boolean(placedLetters[spot] === spot)
    }

    const props = {
        gameCompleted,
        handleReset,
        gameID
    }

    const sensors = useSensors(
        useSensor(MouseSensor),
        useSensor(KeyboardSensor),
        useSensor(TouchSensor)
    )

    return (
        <Transition className={`game game${gameID}`}>
            <style scoped>{css}</style>
            <div className='container'>
                <DndContext
                    modifiers={[restrictToFirstScrollableAncestor]}
                    sensors={sensors}
                    onDragEnd={handleDragEnd}
                >
                    <div className='placementArea'>
                        {letters.map((letter, index) => {
                            return (
                                <div key={`drop-${index}`} className='spot'>
                                    <Droppable id={letter.spot + '_' + letter.letter} disabled={isLetterPlaced(letter.spot)}>
                                        { isLetterPlaced(letter.spot) &&
                                            <img src={require(`assets/images/game_${gameID}/placed_letter_${letter.letter}.png`)} alt={letter.letter} />
                                        }
                                    </Droppable>
                                </div>
                            )
                        })}
                    </div>
                    <div className='letterContainer margin-footer-logo'>
                        {shuffledLetters.map((letter, index) => {
                            return (
                                <div key={`drag-${index}`} className='spot'>
                                    {!isLetterPlaced(letter.spot) && (
                                        <Draggable id={letter.spot + '_' + letter.letter}>
                                            <img src={require(`assets/images/game_${gameID}/button_letter_${letter.letter}.png`)} alt={letter.letter} />
                                        </Draggable>
                                    )}
                                </div>
                            )
                        })}
                    </div>
                </DndContext>
            </div>

            <CSSTransition
                in={showGameCompleteImage}
                timeout={750}
                classNames='fade'
            >
                <div className='endGameContainer fade-enter' style={{ transitionDuration: '750ms' }}>
                    <img src={require(`assets/images/game_${gameID}/game_complete.jpg`)} />
                </div>
            </CSSTransition>

            <Navigation charater='button_bert' Help={Help} helpProps={props} />
        </Transition>
    )
}