import { useEffect, useState, useContext } from 'react'
import { CSSTransition } from 'react-transition-group';
import { DndContext, useSensor, MouseSensor, TouchSensor, KeyboardSensor, useSensors, closestCenter } from '@dnd-kit/core'
import { arrayMove, SortableContext, horizontalListSortingStrategy } from "@dnd-kit/sortable"
import { restrictToHorizontalAxis, restrictToParentElement } from "@dnd-kit/modifiers"
import classnames from 'classnames'

import { SaveContext, CompleteGame } from 'components/save'
import Navigation from 'components/navigation'
import Transition from 'components/navigation/transition'
import AudioPlayer from 'components/audio'
import Help from './help'
import { Sortable } from './sortable'
import ArrowLeft from 'assets/vectors/arrow_left.svg'
import ArrowRight from 'assets/vectors/arrow_right.svg'
import FoundCounter from './foundCounter'
import './game10.scss'

import random from 'components/functions/random'

const audioPlayer = AudioPlayer()
audioPlayer.setInstrument('/assets/instrument-mp3.js') // Public folder

export default function Game10() {

    const gameID = 10
    const { save, setSave } = useContext(SaveContext)

    const [gameCompleted, setGameCompleted] = useState(false)
    const [showGameCompleteImage, setShowGameCompleteImage] = useState(false)

    const [paused, setPaused] = useState(true)
    const foundCount = 5
    const [found, setFound] = useState(0)
    const spotSize = 8
    const [spots, setSpots] = useState(Array.from({ length: spotSize }, (_, i) => i + 1))
    const dropTypes = ['love', 'blank', 'banana']
    const [drops, setDrops] = useState([])

    const getRandomIntInclusive = (min, max) => {
        min = Math.ceil(min)
        max = Math.floor(max)
        return Math.floor(random() * (max - min + 1) + min)
    }

    const [randomPostcard, setRandomPostcard] = useState(getRandomIntInclusive(1, 5))

    const handleReset = () => {
        setDrops([])
        setFound(0)
        setPaused(true)
        setGameCompleted(false)
        setShowGameCompleteImage(false)

        // Update Image after fadeout
        setTimeout(() => setRandomPostcard(getRandomIntInclusive(1, 5)), 1000)
    }

    const handleGameCompleted = () => {
        CompleteGame({ id: gameID, save, setSave })
        setPaused(true)

        setTimeout(() => {
            setShowGameCompleteImage(true)
        }, 1500)

        setTimeout(() => {
            setGameCompleted(true)
        }, 6500)
    }

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown)

        return () => document.removeEventListener('keydown', handleKeyDown)
    }, [])

    const handleKeyDown = (e) => {
        if (e.repeat) return

        const key = e.key
        if (key === 'ArrowLeft') changeSpot('left')
        if (key === 'ArrowRight') changeSpot('right')
    }

    useEffect(() => {
        if (paused) return

        const interval = setInterval(() => {
            if (gameCompleted) clearInterval(interval)

            const timestamp = Date.now()
            const date = new Date()

            const newDrop = {
                id: timestamp,
                spot: Math.floor(random() * spotSize),
                type: dropTypes[Math.floor(random() * dropTypes.length)],
                rotation: getRandomIntInclusive(-45, 45),
                scale: getRandomIntInclusive(70, 110),
                tick: -1
            }

            setDrops((prevDrops) => {
                let newDrops = prevDrops
                if (date.getSeconds() % 2 === 0) {
                    newDrops = [...prevDrops, newDrop]
                }

                return newDrops
                    .filter(drop => drop.tick < 10)
                    .map(drop => (
                        { ...drop, tick: drop.tick + 1 }
                    ))
            })
        }, 1000)

        return () => clearInterval(interval)
    }, [paused, gameCompleted])

    useEffect(() => {
        const element = drops.find(drop => drop.tick === 10)

        // Is it caught?
        if (element?.spot === currentSpot) {
            if (element.type === 'love') {
                incrementFound(+1)
            } else if (element.type === 'banana') {
                incrementFound(-1)
            }
        }
    }, [drops])

    const handleDrag = (event) => {
        const { active, over } = event

        if (!over) return

        if (active.id !== over.id) {
            setSpots((spots) => {
                const oldIndex = spots.indexOf(active.id)
                const newIndex = spots.indexOf(over.id)

                return arrayMove(spots, oldIndex, newIndex)
            })
        }
    }

    const changeSpot = (direction) => {
        setSpots((spots) => {
            const oldIndex = spots.indexOf(1)
            const newIndex = direction === 'left' ? oldIndex - 1 : oldIndex + 1

            if (newIndex < 0 || newIndex > spots.length - 1) return spots

            return arrayMove(spots, oldIndex, newIndex)
        })
    }

    const currentSpot = spots.findIndex(spot => spot === 1)

    const playNote = (pitch, position, delay) => {
        setTimeout(() => audioPlayer.playNote(pitch * 2 + position + 67), delay)
    }

    const incrementFound = (amount) => {
        if (amount == 1) {
            const pitch = found
            if (pitch === foundCount - 1) {
                // Play success soundeffect
                playNote(0, 9, 500)
                playNote(0, 9, 800)
                playNote(0, 9, 900)
                playNote(0, 15, 1150)
                playNote(0, 11, 1300)
                playNote(0, 15, 1450)
            } else if (pitch < foundCount) {
                // Play collect soundeffect
                playNote(pitch, 0, 500)
                playNote(pitch, 5, 650)
                playNote(pitch, 7, 800)
            }
        } else {
            // Play fail soundeffect
            playNote(0, -8, 500)
            playNote(0, -14, 700)
        }

        setFound((prevFound) => {
            const newFound = prevFound + amount
            if (newFound < 0) return 0
            if (newFound >= foundCount) {
                handleGameCompleted()
                return foundCount
            }
            return newFound
        })
    }

    const getStyle = (drop, correctSpot) => {
        const top = (drop.tick === 10 && !correctSpot ? 115 : drop.tick * 10) + '%'
        const opacity = drop.tick < 10 || correctSpot ? 1 : 0
        const transform = `rotate(${drop.rotation}deg) scale(${drop.scale / 100})`

        return { top, opacity, transform }
    }

    const props = {
        gameCompleted,
        handleReset,
        gameID,
        foundCount,
        setPaused
    }

    const sensors = useSensors(
        useSensor(MouseSensor),
        useSensor(KeyboardSensor),
        useSensor(TouchSensor)
    )

    return (
        <Transition className={`game game${gameID}`} style={{backgroundImage:'url('+require(`assets/images/game_${gameID}/background.jpg`)+')'}}>
            <div className='container'>
                <div className='gameContainer'>
                    <button onClick={() => changeSpot('left')}><img src={ArrowLeft} /></button>
                    <div className='catchAreaContainer'>
                        <div className='drops'>
                            {drops.map(drop => (
                                <img
                                    key={drop.id}
                                    className={classnames('drop', drop.type, 'spot-' + (drop.spot + 1))}
                                    src={require(`assets/images/game_${gameID}/letter_${drop.type}.png`)}
                                    style={getStyle(drop, spots[drop.spot] == 1)}
                                />
                            ))}
                        </div>
                        <div className='catchArea'>
                            <DndContext
                                modifiers={[restrictToHorizontalAxis, restrictToParentElement]}
                                sensors={sensors}
                                collisionDetection={closestCenter}
                                onDragOver={handleDrag}
                            >
                                <SortableContext items={spots} strategy={horizontalListSortingStrategy}>
                                    {spots.map((el, i) => (
                                        <Sortable key={spots[i]} index={i} id={el} gameID={gameID} />
                                    ))}
                                </SortableContext>
                            </DndContext>
                        </div>
                    </div>
                    <button onClick={() => changeSpot('right')}><img src={ArrowRight} /></button>
                </div>
                <FoundCounter found={found} foundCount={foundCount} gameID={gameID} />
            </div>

            <CSSTransition
                in={showGameCompleteImage}
                timeout={750}
                classNames='fade'
            >
                <div className='endGameContainer fade-enter' style={{ transitionDuration: '750ms' }}>
                    <img src={require(`assets/images/game_${gameID}/postcard_0${randomPostcard}.jpg`)} />
                </div>
            </CSSTransition>

            <Navigation charater='button_berta' Help={Help} helpProps={props} />
        </Transition>
    )
}